import cornerstoneTools from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';
import { vec2, vec3 } from 'gl-matrix';
import Obb from './Obb';

const BaseTool = cornerstoneTools.import('base/BaseTool');
const drawLine = cornerstoneTools.import('drawing/drawLine');
const drawRect = cornerstoneTools.import('drawing/drawRect');
const getNewContext = cornerstoneTools.import('drawing/getNewContext');
const MouseCursor = cornerstoneTools.import('tools/cursors/MouseCursor');

const getVerticesId = (v) => v && `${v.labeluuid}${v.index}`;

const patient2image = (geometry, p) => {
  const { rowVec, colVec, ipp, spacing } = geometry;
  const AB = vec3.sub(vec3.create(), p, ipp);
  return vec2.fromValues(vec3.dot(rowVec, AB) / spacing[1], vec3.dot(colVec, AB) / spacing[0]);
};

const getClosestVertices = (vertices, threshold) => {
  const closest = vertices.reduce((acc, cur) => (cur.distance < acc.distance ? cur : acc), {
    distance: 9999,
  });

  if (closest.distance < threshold) {
    return closest;
  }
};

const isActive = (element) => {
  return cornerstoneTools.getToolForElement(element, 'BorderNoLabel').mode === 'active';
};

const isEnabled = (element) => {
  return cornerstoneTools.getToolForElement(element, 'BorderNoLabel').mode === 'enabled';
};

const getToolState = (element) => {
  const toolstate = cornerstoneTools.getToolState(element, 'BorderNoLabel');
  if (!toolstate || !toolstate.data || !toolstate.data.length) {
    return;
  }
  return toolstate.data[0];
};

class DrawBorderTool extends BaseTool {
  constructor(props) {
    const defaultProps = {
      name: 'BorderNoLabel',
      supportedInteractionTypes: ['Touch'],
    };
    super(props, defaultProps);
  }

  renderToolData(evt) {
    const { canvasContext, element, enabledElement } = evt.detail;
    const toolstate = getToolState(element);
    if (!toolstate) {
      console.log('ERROR no tool state')
      return;
    }

    const context = getNewContext(canvasContext.canvas);

    if (!isEnabled(element)) {
      return;
    }

    const image = enabledElement.image.metadata;
    const imageRows = enabledElement.image.rows;
    const imageColumns = enabledElement.image.columns;

    const horiLineWidth = 0.05*imageRows
    const vertiLineWidth = 0.05*imageColumns

    drawRect(context,
      element,
      { x: 0, y: 0 },
      { x: imageColumns-0.05*imageColumns, y: 0.05*imageRows},
      {
             color: '#FF000033',
             lineWidth: 0,
             fillStyle: '#FF000033',
             shouldDrawLines: false,
      });
    drawRect(context,
      element,
      { x: 0, y: 0.05*imageRows },
      { x: 0.05*imageColumns, y: imageRows},
      {
              color: '#FF000033',
              lineWidth: 0,
              fillStyle: '#FF000033',
              shouldDrawLines: false,
      });
    drawRect(context,
      element,
      { x: 0.05*imageColumns, y: imageRows-0.05*imageRows },
      { x: imageColumns, y: imageRows},
      {
              color: '#FF000033',
              lineWidth: 0,
              fillStyle: '#FF000033',
              shouldDrawLines: false,
      });
    drawRect(context,
      element,
      { x: imageColumns-0.05*imageColumns, y: 0 },
      { x: imageColumns, y: imageRows-0.05*imageRows},
      {
              color: '#FF000033',
              lineWidth: 0,
              fillStyle: '#FF000033',
              shouldDrawLines: false,
      });
  }
}

export { DrawBorderTool, DrawBorderTool as default };
