import React from 'react';
import Thumbnail from './Thumbnail';
import cornerstone from 'cornerstone-core';

import { EuiText } from '@elastic/eui';

const viewportTagPadding = 10;
const CLOSEST_IMAGE_ENABLED = false;
const styles = {
  '@keyframes color-me-in': {
    /* You could think of as "step 1" */
    '0%': {
      color: 'orange',
    },
    '70%': {
      color: 'orange',
    },
    /* You could think of as "step 2" */
    '100%': {
      color: 'inherit',
    },
  },
  flashBorder: {
    animation: '$color-me-in-border 2s',
  },
  '@keyframes color-me-in-border': {
    /* You could think of as "step 1" */
    '0%': {
      'border-color': 'orange',
    },
    '50%': {
      'color-border': 'orange',
    },
    /* You could think of as "step 2" */
    '100%': {
      'color-border': 'inherit',
    },
  },
  flash: {
    animation: '$color-me-in 1s',
  },
  avatar: {
    animation: '$color-me-in-border 1s',
    'border-width': '1px',
    'border-style': 'solid',
    borderColor: 'transparent',
    width: 300,
    height: 300,
    marginLeft: 'auto',
  },
  topLeft: {
    position: 'absolute',
    pointerEvents: 'none',
    top: viewportTagPadding,
    left: viewportTagPadding,
    textShadow: '1px 1px #00000099',
  },
  topCenter: {
    position: 'absolute',
    pointerEvents: 'none',
    top: viewportTagPadding,
    paddingTop: viewportTagPadding,
    width: '100%',
    textAlign: 'center',
    textShadow: '1px 1px #00000099',
  },
  topRight: {
    position: 'absolute',
    pointerEvents: 'none',
    top: viewportTagPadding,
    right: viewportTagPadding,
    textAlign: 'right',
    textShadow: '1px 1px #00000099',
  },
  bottomLeft: {
    position: 'absolute',
    pointerEvents: 'none',
    bottom: viewportTagPadding + 25,
    left: viewportTagPadding,
    textShadow: '1px 1px #00000099',
  },
  bottomRight: {
    position: 'absolute',
    pointerEvents: 'none',
    bottom: viewportTagPadding + 25,
    right: viewportTagPadding,
    textAlign: 'right',
    textShadow: '1px 1px #00000099',
  },
};

const toFixed = (val, n) => {
  if (!val || typeof val.toFixed !== 'function') {
    return '';
  }

  return `${val.toFixed(n)}`;
};

const getPredictionText = (seriesMetadata, predictions, imageuuid) => {
  const instancePrediction =
    predictions[seriesMetadata.seriesinstanceuid] &&
    predictions[seriesMetadata.seriesinstanceuid].find((p) => p.imageuuid === imageuuid);


  if (!instancePrediction) return;

  if (instancePrediction.status !== 'success') return instancePrediction.status
  /* 
   * if highest prediction is less than 0.5
   * use max value as threshold instead of 0.5
   */
  const maxValuePred = Math.max(...Object.values(instancePrediction.probabilities))
  const maxValuePostPred = instancePrediction.postProcessedProbabilities && Math.max(...Object.values(instancePrediction.postProcessedProbabilities))

  const predString = Object.entries(instancePrediction.probabilities)
    .filter((a) => a[1] > 0.5 ||  a[1] >= maxValuePred)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map((p) => `${p[0]} (${p[1].toFixed(3)}) `).join(', ');

  const postProcessedPredString = instancePrediction.postProcessedProbabilities &&
     Object.entries(instancePrediction.postProcessedProbabilities  || instancePrediction.probabilities)
    .filter((a) => a[1] > 0.5 ||  a[1] >= maxValuePostPred)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map((p) => `${p[0]} (${p[1].toFixed(3)}) `).join(', ');

  return `bp: ${predString}${postProcessedPredString ? ' | post: ' + postProcessedPredString : ''}`
};

class DicomOverlay extends React.Component {
  abortController = new AbortController();

  constructor(props) {
    super(props);
    this.state = {
      closestSeries: undefined,
      closestImage: undefined,
    };
    if (CLOSEST_IMAGE_ENABLED) {
      fetch(`/seriesapi/uuid/${this.props.seriesMetadata.seriesuuid}`, {
        signal: this.abortController.signal,
      })
        .then((r) => r.text())
        .then((data) => {
          if (!data || data === 'None') return;
          this.setState({ closestSeries: data });
        })
        .catch(() => {});
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      CLOSEST_IMAGE_ENABLED &&
      nextState.closestSeries &&
      this.closesTimer === undefined &&
      (nextState.closestImage === undefined || this.props.imageIndex !== nextProps.imageIndex)
    ) {
      nextState.closestImage = '';
      clearTimeout(this.closesTimer);
      this.closesTimer = undefined;
      this.closesTimer = setTimeout(() => {
        const imageIndex = nextProps.imageIndex;
        const uuid = this.props.seriesMetadata.images[imageIndex].imageuuid;
        fetch(`/modelapi/findclosest/${nextState.closestSeries}/${uuid}`, {
          signal: this.abortController.signal,
        })
          .then((r) => r.text())
          .then((data) => {
            this.closesTimer = undefined;
            if (!data || data === 'None') return;
            this.setState({ closestImage: data });
          })
          .catch(() => {
            this.closesTimer = undefined;
          });
      }, 66);
    }

    if (this.state.closestImage !== nextState.closestImage && nextState.closestImage) {
      const closestImageId = `rawpixels://api/images/transcode/${nextState.closestImage}`;
      const imageMetadata = this.props.imageMetadata;
      const hInterval = setInterval(() => {
        if (imageMetadata.has(closestImageId)) {
          clearInterval(hInterval);
          this.setState({ closestImagePatientName: imageMetadata.get(closestImageId).patientname });
        }
      }, 50);
    }

    if (
      this.state.closestImagePatientName !== nextState.closestImagePatientName ||
      this.state.closestImage !== nextState.closestImage ||
      this.props.predictions !== nextProps.predictions ||
      this.props.imageIndex !== nextProps.imageIndex ||
      this.props.scale !== nextProps.scale ||
      this.props.voi[0] !== nextProps.voi[0] ||
      this.props.voi[1] !== nextProps.voi[1] ||
      this.props.activeToolName !== nextProps.activeToolName
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.abortController && this.abortController.abort();
  }

  render() {
    const { closestImage, closestImagePatientName } = this.state;
    const {
      studyMetadata,
      seriesMetadata,
      imageIndex,
      scale,
      predictions,
      voi,
      activeToolName,
    } = this.props;
    const { patientname, studydescription, studydate, patientid } = studyMetadata;
    const { seriesdescription } = seriesMetadata;
    const { instancenumber, slicethickness, slicelocation, imageuuid } = seriesMetadata.images[
      imageIndex
    ];

    const numImages = seriesMetadata.images.length;
    // const onThumbnailClick = (event) => {
    //   event.stopPropagation();
    //   // FIXME: Hardcoded link, wth?
    //   window.open(`https://jfpb-viewer.35.224.253.200.nip.io/view?image=${closestImage}`);
    // };
    const predictionText = getPredictionText(seriesMetadata, predictions, imageuuid)
    // const closestImageId = closestImage && `rawpixels://api/images/transcode/${closestImage}`;
    return (
      <div>
        <div style={styles.topLeft}>
          {seriesdescription && <EuiText size='xs' color={"subdued"}> {seriesdescription}</EuiText>}
          {patientname && <EuiText size='xs' color={"subdued"}> {patientname}</EuiText>}
          {patientid && <EuiText size='xs' color={"subdued"}> {patientid}</EuiText>}
          {predictionText && (
            <EuiText size='xs' color={"subdued"}>
              {predictionText}
            </EuiText>
          )}
        </div>

        <div style={styles.topRight}>
          {studydescription && <EuiText size='xs' color={"subdued"}> {studydescription}</EuiText>}
          {studydate && <EuiText size='xs' color={"subdued"}> {studydate}</EuiText>}
        </div>

        <div style={styles.bottomLeft}>
          {slicelocation && (
            <EuiText size='xs' color={"subdued"}> {`Loc: ${toFixed(slicelocation, 1)}`}</EuiText>
          )}
          <EuiText
            size='xs'
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
            color={"subdued"}
            onClick={() => {
              navigator.clipboard.writeText(`${window.location.origin}/view?image=${imageuuid}`);
            }}
          >
            {numImages > 1 ? `Img: ${instancenumber} /${numImages}` : `Img: ${instancenumber}`}
          </EuiText>
          {slicethickness && (
            <EuiText size='xs' color={"subdued"}> {`Thick: ${slicethickness} mm`}</EuiText>
          )}
        </div>

        <div style={styles.bottomRight}>
          {/* {closestImage && (
            <Paper
            style={styles.avatar}
              style={{ pointerEvents: 'auto', cursor: 'pointer', position: 'relative' }}
              onClick={onThumbnailClick}
            >
              {closestImagePatientName && (
                <EuiText
                  style={{ color: '#fff', position: 'absolute', zIndex: 300, left: '4px' }}
                >
                  {closestImagePatientName}
                </EuiText>
              )}
              <Thumbnail cornerstone={cornerstone} imageId={closestImageId} />
            </Paper>
          )} */}
          {activeToolName && <EuiText size='xs' color={"subdued"}> Tool: {activeToolName}</EuiText>}
          <EuiText size='xs' color={"subdued"}> Zoom: {toFixed(scale * 100, 0)}%</EuiText>
          <EuiText size='xs' color={"subdued"}>
            {' '}
            WW/WC: {toFixed(voi[0], 0)} / {toFixed(voi[1], 0)}
          </EuiText>
        </div>
      </div>
    );
  }
}

export default DicomOverlay;
