import React from 'react';
import Thumbnail from './Thumbnail';
import SeriesListItem from './SeriesListItem';
import cornerstone from 'cornerstone-core';
import withLabelStore from '../context/Labels';
import { observer } from 'mobx-react';

import { EuiCollapsibleNavGroup, EuiSpacer } from '@elastic/eui';

const getSeriesPredictions = (seriesinstanceuid, predictions) => {
  if (
    !predictions[seriesinstanceuid] ||
    !predictions[seriesinstanceuid].length ||
    predictions[seriesinstanceuid].length < 2
  ) {
    return;
  }

  const count = predictions[seriesinstanceuid]
    .filter((i) => i.status === 'success')
    .reduce((acc, cur) => {
      console.log(cur)
 
      //const predictions = Object.entries(cur.postProcessedProbabilities || cur.probabilities)
      //  .filter((e) => e[1] > 0.5)
      /* use classifier predictions ONLY */
       const maxValue = Math.max(...Object.values(cur.probabilities))
       const predictions = Object.entries(cur.probabilities)
        .filter((e) => e[1] > 0.5 || e[1] >= maxValue)
        .map((e) => e[0]);
      predictions.forEach((p) => {
        acc[p] = (acc[p] || 0) + 1;
      });
      return acc;
    }, {});

  let seriesContent = [];
  for (const [key, value] of Object.entries(count)) {
    seriesContent.push(`${value} ${key}`);
  }
  return seriesContent.join(', ');
};

class SeriesList extends React.Component {
  render() {
    const { labelStore, currentSeries, studyMetadata, onSeriesChange, predictions } = this.props;
    const foruuids = [...new Set(studyMetadata.series.map((s) => s.foruuid))];
    return (
      <EuiCollapsibleNavGroup
        title="Series"
        iconType="emsApp"
        titleSize="xs"
        isCollapsible={false}
        style={{overflowX:'hidden'}}
      >
        <EuiSpacer size="xs" />
        {studyMetadata.series.length > 0 &&
          studyMetadata.series.map((s, i) => (
            <React.Fragment key={s.seriesuuid}>
            <SeriesListItem
              seriesuuid={s.seriesuuid}
              modality={s.modality}
              predictionText={getSeriesPredictions(s.seriesinstanceuid, predictions)}
              seriesdescription={s.seriesdescription}
              seriesnumber={s.seriesnumber}
              seriesdate={s.seriesdate}
              index={i}
              isSelectedLeft={i === currentSeries[0]}
              isSelectedRight={i === currentSeries[1]}
              onClickCallback={onSeriesChange}
              forIndex={s.foruuid ? foruuids.indexOf(s.foruuid) : -1}
              numberOfLabels={labelStore.labels.reduce(
                (acc, cur) => (acc += cur.label.seriesuuid === s.seriesuuid ? 1 : 0),
                0
              )}
              isLast={i === studyMetadata.series.length - 1}
            >
              <Thumbnail
                cornerstone={cornerstone}
                imageId={s.imageIds[Math.floor(s.imageIds.length / 2)]}
              />
            </SeriesListItem>
            <EuiSpacer size="s" />
            </React.Fragment>
          ))}
      </EuiCollapsibleNavGroup>
    );
  }
}

export default withLabelStore(observer(SeriesList));
