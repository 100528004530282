import React from 'react';
import classNames from 'classnames';

import { EuiPanel, EuiText, EuiFlexGroup, EuiFlexItem, EuiNotificationBadge } from '@elastic/eui';

function MyAvatar(props) {
  const classes = classNames('euiAvatar', 'euiAvatar--l', 'euiAvatar--user');
  return <div className={classes}>{props.children}</div>;
}

class SeriesListItem extends React.Component {

  render() {
    const {
      seriesdescription,
      index,
      onClickCallback,
      isSelectedLeft,
      isSelectedRight,
      children,
      numberOfLabels,
      predictionText,
    } = this.props;
    return (
      <EuiPanel
        paddingSize="s"
        hasShadow={isSelectedLeft || isSelectedRight}
        onClick={() => {
          onClickCallback(index, 0);
        }}
        onContextMenu={(e) => {
          onClickCallback(index, 1);
          e.preventDefault();
        }}
      >
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false} style={{position: 'relative'}}>
          {numberOfLabels>0 && <EuiNotificationBadge style={{position: 'absolute', zIndex:'1'}}>{numberOfLabels}</EuiNotificationBadge> }
            <MyAvatar>{children}</MyAvatar>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText color={isSelectedLeft ? "secondary" : isSelectedRight ? "warning" : "subdued"}>{seriesdescription}</EuiText>
            {predictionText && <EuiText color="subdued" size="s">{predictionText}</EuiText>}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    );
  }
}

export default SeriesListItem;
