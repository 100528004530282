const { ImageMessage, ImageRequestMessage } = require('../lib/image.proto');

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

const wsurl = new URL('/ws', window.location.href);
wsurl.protocol = wsurl.protocol.replace('http', 'ws');

const imageDeferredMap = new Map();

let _socket = undefined;
const getSocket = (url) => {
  if (_socket && _socket.readyState === WebSocket.OPEN) {
    return Promise.resolve(_socket);
  }

  if (_socket && _socket.readyState === WebSocket.CONNECTING) {
    return new Promise(function (resolve, reject) {
      _socket.addEventListener('open', () => resolve(_socket));
      _socket.addEventListener('error', (err) => reject(err));
    });
  }

  return new Promise(function (resolve, reject) {
    _socket = new WebSocket(url);
    _socket.binaryType = 'arraybuffer';
    _socket.addEventListener('open', () => resolve(_socket));
    _socket.addEventListener('error', (err) => reject(err));
    _socket.addEventListener('message', (event) => {
      const buffer = new Uint8Array(event.data);
      const messageDec = ImageMessage.decode(buffer);
      const imageDeferred = imageDeferredMap.get(messageDec.url);
      if (!imageDeferred) {
        console.log('Where is my deferred');
      }
      imageDeferredMap.delete(messageDec.url);
      imageDeferred.resolve(messageDec.pixelData);
    });
  });
};

// eslint-disable-next-line no-unused-vars
const fetchImage = async (url, options) => {
  const socket = await getSocket(wsurl);
  const imageId = url.split('/').slice(-1)[0];

  const message = ImageRequestMessage.create({
    op: ImageRequestMessage.Op.ADD,
    urls: [imageId],
  });
  const buffer = ImageRequestMessage.encode(message).finish();
  socket.send(buffer);

  let imageDeferred = new Deferred();

  imageDeferredMap.set(imageId, imageDeferred);

  return imageDeferred.promise;
};

export { fetchImage, fetchImage as default };
