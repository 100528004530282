import React from 'react';
import LabelListItem from './LabelListItem';
import withLabelStore from '../context/Labels';
import { observer } from 'mobx-react';

import {
  EuiCollapsibleNavGroup,
  EuiSpacer,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
} from '@elastic/eui';

class LabelList extends React.Component {
  state = {
    insertMenuOpened: false,
  };

  saveAllLabels = () => {
    const labelStore = this.props.labelStore;
    labelStore.labels.forEach((w) => {
      labelStore.saveLabel(w.label.labeluuid);
    });
  };

  render() {
    const { seriesuuid, foruuid, labelStore } = this.props;

    const seriesLabels = labelStore.labels.filter(
      (w) => w.label.type === 'range' && w.label.seriesuuid === seriesuuid
    );
    const forLabels = labelStore.labels.filter(
      (w) => w.label.type === 'forobb' && w.label.payload.foruuid === foruuid
    );
    const labels = seriesLabels.concat(forLabels);

    const anyUnsaved = labels.findIndex((w) => !w.committed) !== -1;

    return (
      <>
        <EuiCollapsibleNavGroup
          title="Labels"
          iconType="addDataApp"
          titleSize="xs"
          isCollapsible={false}
          style={{overflowX:'hidden'}}
        >
          <EuiSpacer size="xs" />
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={1}></EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiButton
                size="s"
                color="secondary"
                isDisabled={!anyUnsaved}
                onClick={this.saveAllLabels}
              >
                Save all
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={1}>
              <EuiButton size="s" color="secondary" onClick={()=>this.props.onAddLabel('range')}>
                Add Range
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={1}></EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          <EuiSpacer size="xs" />
          {labels.length > 0 &&
            labels.map((w, i) => (
              <React.Fragment key={w.label.labeluuid}>
                <LabelListItem
                  wrappedLabel={w}
                  isLast={i === labels.length - 1}
                  color={w.color}
                  isSelected={labelStore.activeLabelUUID === w.label.labeluuid}
                />
                <EuiSpacer size="s" />
              </React.Fragment>
            ))}
        </EuiCollapsibleNavGroup>
      </>
    );
  }
}

export default withLabelStore(observer(LabelList));
