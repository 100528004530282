import React from 'react';
import withLabelStore from '../context/Labels';
import { observer } from 'mobx-react';
import {when} from 'mobx'
import {
  EuiComboBox,
  EuiPopover,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';

class BoxLabelPopover extends React.Component {
  state = {
    selectedOptions: [],
    confirmDelete: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.isOpen !== prevProps.isOpen){
      this.setState({confirmDelete: false})
      if(this.props.isOpen){
        const labels = this.props.wrappedLabel.label.label.split(';');
        const labelOptions = this.props.labelStore.allowedLabels.map(l => l.options).flat()
        this.setState({selectedOptions: labelOptions.filter(lo => labels.includes(lo.label))});
      }
    }
  }

  onChange = selectedOptions => {
    // console.log(toJS(selectedOptions), selectedOptions[0])
    this.setState({
      selectedOptions,
    });
    const {wrappedLabel} = this.props;
    wrappedLabel.committed = false;
    if (selectedOptions.length > 0) {
      wrappedLabel.label.label = selectedOptions.map(so => so.label).join(';');
    }
  };

  deleteLabel = wrappedLabel => {
    const labelStore = this.props.labelStore;
    labelStore.deleteLabel(wrappedLabel.label.labeluuid);
    when(() => wrappedLabel.pendingDelete === false, this.props.closePopoverCallback);
  };

  saveLabel = wrappedLabel => {
    const labelStore = this.props.labelStore;
    labelStore.saveLabel(wrappedLabel.label.labeluuid);
    when(() => wrappedLabel.pendingSave === false, this.props.closePopoverCallback);
  };

  render() {
    const { wrappedLabel, labelStore, isOpen, button, closePopoverCallback, anchorPosition} = this.props;
    const { confirmDelete, selectedOptions } = this.state;

    return (
        <EuiPopover
          id="popover"
          // ownFocus
          button={button}
          isOpen={isOpen}
          closePopover={closePopoverCallback}
          anchorPosition={anchorPosition || 'downCenter'}
        >
          <div style={{ width: '300px' }}>
            <EuiForm component="div">
              <EuiFormRow
                display="rowCompressed"
                label="Labels field"
                helpText="Select one or more labels."
              >
                <EuiComboBox
                  isClearable={false}
                  compressed={true}
                  placeholder="Select or create options"
                  options={labelStore.allowedLabels}
                  selectedOptions={selectedOptions}
                  onChange={this.onChange}
                />
              </EuiFormRow>
              <EuiSpacer />

              <EuiFlexGroup gutterSize="s" alignItems="center">
                <EuiFlexItem grow={1} />
                <EuiFlexItem grow={false}>
                  <EuiButton
                    isDisabled={wrappedLabel.committed || wrappedLabel.pending}
                    isLoading={wrappedLabel.pendingSave}
                    size="s"
                    color="secondary"
                    onClick={() => this.saveLabel(wrappedLabel)}
                  >
                    Save
                  </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  {!confirmDelete ? (
                    <EuiButton
                      // isDisabled={!wrappedLabel.committed || wrappedLabel.pending}
                      isLoading={wrappedLabel.pendingDelete}
                      size="s"
                      color="danger"
                      onClick={() => {
                        this.setState({ confirmDelete: true });
                      }}
                    >
                      Delete
                    </EuiButton>
                  ) : (
                    <EuiButton
                      size="s"
                      fill
                      color="danger"
                      onClick={() => this.deleteLabel(wrappedLabel)}
                    >
                      Can't be undone
                    </EuiButton>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </div>
        </EuiPopover>
    );
  }
}

export default withLabelStore(observer(BoxLabelPopover));
