import React from 'react';
import withLabelStore from '../context/Labels';
import { observer } from 'mobx-react';
import { EuiIcon } from '@elastic/eui';
import BoxLabelPopover from './BoxLabelPopover';

const blackHole = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

class LabelOverlay extends React.Component {
  state = {
    isPopoverOpen: false,
  };

  togglePopover = (e) => {
    blackHole(e);
    this.state.isPopoverOpen ? this.closePopover() : this.openPopover();
  };

  openPopover = (e) => {
    document.addEventListener('mousemove', blackHole, true);
    this.setState({
      isPopoverOpen: true,
    });
  };

  closePopover = (e) => {
    document.removeEventListener('mousemove', blackHole, true);
    this.setState({
      isPopoverOpen: false,
    });
  };

  render() {
    const { labelStore } = this.props;
    const { isPopoverOpen } = this.state;
    const { activeLabelWrapped: wrappedLabel, activePoint } = labelStore;

    if (!activePoint || !wrappedLabel) {
      return '';
    }

    const button = (
      <div onMouseDown={this.togglePopover}>
        <EuiIcon type="iInCircle" />
      </div>
    );

    return (
      <div
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: activePoint.y + 3,
          left: activePoint.x + 3,
          zIndex: 10,
        }}
      >
        <BoxLabelPopover
          button={button}
          isOpen={isPopoverOpen}
          closePopoverCallback={this.closePopover}
          wrappedLabel = {wrappedLabel}
          anchorPosition="downCenter"
        ></BoxLabelPopover>
      </div>
    );
  }
}

export default withLabelStore(observer(LabelOverlay));
