import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import signOut from '../lib/signOut';
import {
  EuiDataGrid,
  EuiText,
  EuiPanel,
  EuiButtonIcon,
  EuiButtonEmpty,
  EuiPopover,
  EuiFieldSearch,
  EuiForm,
  EuiFormRow,
  EuiDatePicker,
  EuiButtonGroup,
} from '@elastic/eui';
import update from 'immutability-helper';


function SearchForm(props) {
  const [modalities, setmodalities] = useState(props.initialParams.modalities || {'MR': true, 'CT': true});
  const [patientname, setpatientname] = useState(props.initialParams.patientname || '');
  const [studydescription, setstudydescription] = useState(props.initialParams.studydescription || '');
  const [collection, setcollection] = useState(props.initialParams.collection || '');
  const [after, setafter] = useState(props.initialParams.after);

  const [timeoutID, setTimeoutID] = useState(undefined);
  useEffect(() => {
    clearTimeout(timeoutID);
    setTimeoutID(
      setTimeout(() => {
        props.onSearchChange({
          modalities,
          patientname,
          studydescription,
          after,
          collection
        });
      }),
      70
    );
  }, [modalities, patientname, studydescription, after, collection]);

  const modalityToggleOptions = [
    {
      id: `MR`,
      label: 'MR',
    },
    {
      id: `CT`,
      label: 'CT',
    },
    {
      id: `US`,
      label: 'US',
    },
    {
      id: `CR`,
      label: 'CR',
    },
  ];

  return (
    <div style={{ width: '300px' }}>
      <EuiForm>
        <EuiFormRow label="Modality" display="columnCompressed">
          <EuiButtonGroup
            legend="Modalities"
            options={modalityToggleOptions}
            idToSelectedMap={modalities}
            onChange={(modality) =>{
              setmodalities(update(modalities, { [modality]: { $set: !modalities[modality] } }))
            }}
            buttonSize="compressed"
            type="multi"
            isFullWidth
            color="ghost"
          />
        </EuiFormRow>

        <EuiFormRow label="Patient Name" display="columnCompressed">
          <EuiFieldSearch
            value={patientname}
            onChange={(e) => {
              setpatientname(e.target.value);
            }}
            isClearable={true}
          />
        </EuiFormRow>
        <EuiFormRow label="Study Desc." display="columnCompressed">
          <EuiFieldSearch
            value={studydescription}
            onChange={(e) => setstudydescription(e.target.value)}
            isClearable={true}
          />
        </EuiFormRow>
        <EuiFormRow label="Added after" display="columnCompressed">
          <EuiDatePicker
            selected={after}
            onClear={() => setafter(undefined)}
            onChange={(date) => setafter(date)}
          />
        </EuiFormRow>
        <EuiFormRow label="Collection" display="columnCompressed">
          <EuiFieldSearch
            value={collection}
            onChange={(e) => setcollection(e.target.value)}
            isClearable={true}
          />
        </EuiFormRow>
      </EuiForm>
    </div>
  );
}



class StudyList extends React.Component {
  constructor(props) {
    super(props);
    props.setBreadCrumbs([{ text: 'Study List' }]);

    const { history } = this.props;

    this.state = { counter: 0 };
    if (history.location.state) {
      this.state = history.location.state;
      if (this.state.lastQuery) {
        this.getData(this.state.lastQuery);
      }
    } else {
      this.state = {
        pageIndex: 0,
        pageSize: 15,
        rowCount: 100,
        sorting: [{ id: 'created', direction: 'desc' }],
        data: [],
        searchParams: {},
        isSearchPopOverOpen: false,
        visibleColumns: [
          'modality',
          'patientsex',
          'patientname',
          'studydescription',
          'collection',
          'created',
        ],
      };
    }

    this.columns = [
      {
        id: 'modality',
        displayAsText: 'Modality',
        display: 'Modality',
        initialWidth: 80,
        isResizable: false,
        isExpandable: false,
      },
      {
        id: 'patientsex',
        displayAsText: 'sex',
        initialWidth: 50,
        isResizable: false,
        isExpandable: false,
      },
      { id: 'labels', displayAsText: 'Study Labels', isResizable: false, isExpandable: false },
      {
        id: 'patientname',
        displayAsText: 'Name',
        initialWidth: 200,
        isExpandable: false,
      },
      {
        id: 'studydescription',
        displayAsText: 'Study Description',
        isExpandable: false,
      },
      { id: 'studydate', displayAsText: 'Study Date', isExpandable: false },
      { id: 'studyinstanceuid', displayAsText: 'Study UID', isExpandable: false },
      {
        id: 'labelcount',
        displayAsText: 'User labels',
        isSortable: false,
        initialWidth: 110,
        isResizable: false,
        isExpandable: false,
      },
      {
        id: 'collection',
        displayAsText: 'Collection',
        initialWidth: 110,
        isResizable: false,
        isExpandable: false,
        searchable: true,
      },
      {
        id: 'created',
        displayAsText: 'Date Added',
        schema: 'datetime',
        initialWidth: 110,
        isResizable: false,
        isExpandable: false,
      },
    ];

    this.columns.forEach((c) => {
      c.display = c.displayAsText;
    });

    this.getData();
  }

  pushStateOnHistory = () => {
    this.props.history.replace(this.props.history.location.pathname, this.state);
  };

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.pushStateOnHistory);
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.pushStateOnHistory);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      pageIndex,
      pageSize,
      sorting,
      searchParams,
      data,
      isSearchPopOverOpen,
      visibleColumns,
      rowCount,
    } = this.state;

    if (
      nextState.pageIndex !== pageIndex ||
      nextState.pageSize !== pageSize ||
      nextState.sorting !== sorting ||
      nextState.searchParams !== searchParams
    ) {
      this.getData(nextState);
    }

    if (
      data !== nextState.data ||
      isSearchPopOverOpen !== nextState.isSearchPopOverOpen ||
      nextState.rowCount !== rowCount ||
      nextState.visibleColumns !== visibleColumns
    ) {
      return true;
    }
    return false;
  };

  getData = (state) => {
    const { pageIndex, pageSize, sorting, searchParams } = state || this.state;
    let query = `?page=${pageIndex}&perpage=${pageSize}`;
    if (sorting.length > 0) {
      query += `&order=${sorting.map((s) => (s.direction === 'desc' ? '-' : '') + s.id).join(',')}`;
    }

    if (searchParams.studydescription && searchParams.studydescription.length > 0) {
      query += `&studydescription=*${searchParams.studydescription}*`;
    }
    if (searchParams.patientname && searchParams.patientname.length > 0) {
      query += `&patientname=*${searchParams.patientname}*`;
    }
    if (searchParams.after) {
      query += `&created=%3E%3D${searchParams.after.format('YYYY-MM-DD')}`;
    }
    if (searchParams.collection && searchParams.collection.length > 0) {
      query += `&collection=*${searchParams.collection}*`;
    }

    if (searchParams.modalities instanceof Object) {
      const modalities = Object.entries(searchParams.modalities).filter(entry => entry[1]).map(entry=> entry[0])
      if(modalities.length > 0) {
        query += `&modality=${modalities.join(',')}`;
      }
    }


    fetch(`/api/studies/list${query || ''}`)
      .then((r) => (r.status === 401 ? signOut() : r.json()))
      .then((data) => {
        if (!data) return;
        this.setState({ data });
        clearTimeout(this.countTimer);
        this.countTimer = setTimeout(() => this.getStudyCount(query), 300);
      })
      .catch(() => this.setState({ data: [] }));
  };

  getStudyCount = (query) => {
    fetch(`/api/studies/count${query || ''}`)
      .then((r) => (r.status === 401 ? signOut() : r.json()))
      .then((data) => data && this.setState({ rowCount: parseInt(data.count, 10) }))
      .catch(() => this.setState({ rowCount: 0 }));
  };

  render() {
    const {
      data,
      rowCount,
      pageSize,
      pageIndex,
      sorting,
      visibleColumns,
      isSearchPopOverOpen,
    } = this.state;

    const renderCell = ({ rowIndex, columnId }) => {
      const index = rowIndex % pageSize;
      return <EuiText size="xs">{data[index] ? data[index][columnId] : '..'}</EuiText>;
    };

    return (
      <EuiPanel>
        <EuiDataGrid
          columns={this.columns}
          leadingControlColumns={[
            {
              id: 'View',
              width: 42,
              headerCellRender: () => null,
              rowCellRender: ({ rowIndex }) => (
                <EuiButtonIcon
                  color="text"
                  iconType="eye"
                  iconSize="m"
                  aria-label="View details"
                  href={data[rowIndex % pageSize] && data[rowIndex % pageSize]['studyinstanceuid'] && `/view?study=${data[rowIndex % pageSize]['studyinstanceuid']}`}
                  onClick={() => {
                    const index = rowIndex % pageSize;
                    if (!data[index] || !data[index]['studyinstanceuid']) return;
                    this.pushStateOnHistory();
                    this.props.history.push(`/view?study=${data[index]['studyinstanceuid']}`);
                  }}
                />
              ),
            },
          ]}
          columnVisibility={{
            visibleColumns,
            setVisibleColumns: (visibleColumns) => {
              this.setState({ visibleColumns });
            },
          }}
          rowCount={rowCount}
          renderCellValue={renderCell}
          sorting={{
            columns: sorting,
            onSort: (sorting) => {
              this.setState({ sorting });
            },
          }}
          pagination={{
            pageIndex,
            pageSize,
            pageSizeOptions: [10, 15, 30],
            onChangeItemsPerPage: (pageSize) => {
              this.setState({ pageSize });
            },
            onChangePage: (pageIndex) => {
              this.setState({ pageIndex });
            },
          }}
          toolbarVisibility={{
            showColumnSelector: true,
            showStyleSelector: false,
            showSortSelector: true,
            showFullScreenSelector: false,
            additionalControls: (
              <EuiPopover
                ownFocus
                anchorPosition="downLeft"
                panelPaddingSize="s"
                button={
                  <EuiButtonEmpty
                    size="xs"
                    iconType="search"
                    color="text"
                    onClick={() => this.setState({ isSearchPopOverOpen: true })}
                    className={'euiDataGrid__controlBtn'}
                  >
                    search
                  </EuiButtonEmpty>
                }
                isOpen={isSearchPopOverOpen}
                closePopover={() => this.setState({ isSearchPopOverOpen: false })}
              >
                <SearchForm initialParams={this.state.searchParams} onSearchChange={(searchParams) => this.setState({searchParams})}/>
              </EuiPopover>
            ),
          }}
          gridStyle={{
            border: 'horizontal',
            fontSize: 'm',
            cellPadding: 'm',
            stripes: true,
            rowHover: 'highlight',
            header: 'shade',
          }}
        />
      </EuiPanel>
    );
  }
}

export default withRouter(StudyList);
