import React from 'react';

const Context = React.createContext({
  useruuid: undefined,
});

const UserStoreProvider = Context.Provider;
const UserStoreConsumer = Context.Consumer;

const withUserStore = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <UserStoreConsumer>{(store) => <Component {...props} userStore={store} />}</UserStoreConsumer>
  );
};

export { withUserStore, withUserStore as default, UserStoreProvider };
