import React from 'react';
import ImageSlider from './ImageSlider';
import DicomOverlay from './DicomOverlay';
import LabelOverlay from './LabelOverlay';
import withLabelStore from '../context/Labels';
import withUserStore from '../context/User';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import { EuiProgress } from '@elastic/eui';

import { DrawBoxTool } from '../lib/DrawBoxTool';
import { DrawBorderTool } from '../lib/DrawBorderTool';

const viewportStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  borderRadius: 'inherit',
  overflow: 'hidden',
};

const viewportElementStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  backgroundColor: 'black',
  outline: '0 !important',
  overflow: 'hidden',
  borderRadius: 'inherit',
  zIndex: '0',
};

class CornerstoneViewport extends React.Component {
  static defaultProps = {
    cornerstoneOptions: {},
    enableStackPrefetch: true,
    imageIds: [],
  };

  getStack = () => {
    const stackData = this.cornerstoneTools.getToolState(this.element, 'stack');
    return stackData && stackData.data[0];
  };

  constructor(props) {
    super(props);

    const { imageIds, initialImageIndex } = props;
    this.cornerstone = props.cornerstone;
    this.cornerstoneTools = props.cornerstoneTools;
    this.scrollToIndex = this.cornerstoneTools.import('util/scrollToIndex');

    const numImages = imageIds.length;
    const currentImageIndex =
      initialImageIndex !== undefined ? initialImageIndex : Math.floor(numImages / 2);

    this.props.updateImageIndexCallback(currentImageIndex);
    let numImagesLoaded = 0;
    imageIds.forEach((id) => {
      if (this.cornerstone.imageCache.getImageLoadObject(id)) {
        numImagesLoaded++;
      }
    });

    this.state = {
      currentImageIndex,
      numImagesLoaded,
      error: null,
      activeToolName: 'Wwwc',
      activeBorder: false,
    };
  }

  render() {
    const { studyMetadata, currentSeries, children, imageIds, imageMetadata, predictions, imageWidth, imageHeight } = this.props;
    const seriesMetadata = studyMetadata.series[currentSeries];
    const viewport = this.element ? this.cornerstone.getViewport(this.element) : undefined;
    const { numImagesLoaded, currentImageIndex, activeToolName } = this.state;
    const loadedPercent = Math.floor(((numImagesLoaded / imageIds.length) * 100) / 3) * 3;
    const { seriesuuid: currentSeriesUUID, seriesinstanceuid } = studyMetadata.series[currentSeries];
    const scores = imageIds.map(id => id.slice(33)).map(uuid => (predictions[seriesinstanceuid] || []).find(p=> p.imageuuid === uuid))


    return (
      <div style={viewportStyle}>
        {viewport && <LabelOverlay element={this.element} />}
        <div
          tabIndex={0}
          onContextMenu={this.onContextMenu}
          style={viewportElementStyle}
          ref={(input) => {
            this.element = input;
          }}
        >
          <canvas
            className="cornerstone-canvas"
            // style={{ position: 'absolute', top: '0', height: '100%' }}
          />
          {viewport && (
            <DicomOverlay
              studyMetadata={studyMetadata}
              seriesMetadata={seriesMetadata}
              imageMetadata={imageMetadata}
              imageIndex={currentImageIndex}
              predictions={predictions}
              scale={viewport.scale}
              voi={[viewport.voi.windowCenter, viewport.voi.windowWidth]}
              activeToolName={activeToolName}
            />
          )}
        </div>

        {loadedPercent < 98 && (
          <EuiProgress value={loadedPercent} max={100} color="subdued" size="xs" />
        )}
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            pointerEvents: 'none',
          }}
        >
          {imageIds.length >= 2 && ( // show image slides for series with 2 or more images
            <ImageSlider
              domain={[0, imageIds.length - 1]}
              current={currentImageIndex}
              onImageIndexUpdate={this.onImageIndexUpdate}
              currentSeriesUUID={currentSeriesUUID}
              scores={scores}
            />
          )}
        </div>
        {children}
      </div>
    );
  }

  onImageIndexUpdate = (index) => {
    this.setState({ currentImageIndex: index });
  };
  onContextMenu = (event) => event.preventDefault();
  onNewImage = () => {};
  onStackScroll = () => this.setState({ currentImageIndex: this.getStack().currentImageIdIndex });
  onImageLoaded = () => this.setState({ numImagesLoaded: this.state.numImagesLoaded + 1 });
  onImageRendered = () => this.forceUpdate();

  componentDidMount() {
    const element = this.element;
    // FIXME: UGLY HACK because the collapsable nav bar slides in.
    setTimeout(()=>this.cornerstone.resize(element), 500)
    const imageIds = this.props.imageIds;
    const currentImageIndex = this.state.currentImageIndex;

    this.eventHandlerData = [
      {
        eventTarget: element,
        eventType: this.cornerstone.EVENTS.IMAGE_RENDERED,
        handler: this.onImageRendered,
      },
      {
        eventTarget: element,
        eventType: this.cornerstone.EVENTS.NEW_IMAGE,
        handler: this.onNewImage,
      },
      {
        eventTarget: element,
        eventType: this.cornerstoneTools.EVENTS.STACK_SCROLL,
        handler: this.onStackScroll,
      },
      {
        eventTarget: this.cornerstone.events,
        eventType: this.cornerstone.EVENTS.IMAGE_LOADED,
        handler: this.onImageLoaded,
      },
    ];

    // Enable the DOM Element for use with Cornerstone
    try {
      this.cornerstone.getEnabledElement(element);
    } catch (error) {
      this.cornerstone.enable(element, this.props.cornerstoneOptions);

      [
        {
          name: 'Length',
        },
        {
          name: 'Wwwc',
        },
        {
          name: 'WwwcRegion',
        },
        {
          name: 'Zoom',
          configuration: {
            minScale: 0.3,
            maxScale: 25,
            preventZoomOutsideImage: true,
          },
        },
        {
          name: 'Pan',
        },
        {
          name: 'ZoomMouseWheel',
        },
        {
          name: 'StackScroll',
        },
        {
          name: 'StackScrollMouseWheel',
        },
        {
          name: 'ReferenceLines'
        },
      ].forEach((tool) => {
        const apiTool = this.cornerstoneTools[`${tool.name}Tool`];
        apiTool && this.cornerstoneTools.addToolForElement(element, apiTool, tool.configuration);
      });

      this.cornerstoneTools.clearToolState(element, 'stack');
      this.cornerstoneTools.addStackStateManager(element, ['stack', 'BoxLabel', 'BorderNoLabel']);
      this.cornerstoneTools.addToolState(element, 'stack', {
        imageIds,
        currentImageIdIndex: currentImageIndex,
      });

      this.cornerstoneTools.clearToolState(element, 'BoxLabel');
      this.cornerstoneTools.addToolState(element, 'BoxLabel', {
        labelStore: this.props.labelStore,
        userStore: this.props.userStore,
      });

      this.cornerstoneTools.clearToolState(element, 'BorderNoLabel');
      this.cornerstoneTools.addToolState(element, 'BorderNoLabel', {
        viewportState: this.state,
      });

      this.cornerstoneTools.addToolForElement(element, DrawBoxTool);
      this.cornerstoneTools.addToolForElement(element, DrawBorderTool);

      this.cornerstoneTools.stackPrefetch.enable(element);
      this.cornerstoneTools.stackPrefetch.setConfiguration({
        maxImagesToPrefetch: Infinity,
        preserveExistingPool: false,
        maxSimultaneousRequests: 30,
      });

      //this.cornerstoneTools.setToolActiveForElement(element, 'BorderNoLabel', { mouseButtonMask: 1 });
      this.cornerstoneTools.setToolDisabledForElement(element, 'BorderNoLabel');

      this.cornerstoneTools.setToolActiveForElement(element, 'Pan', { mouseButtonMask: 4 });
      this.cornerstoneTools.setToolActiveForElement(element, 'Zoom', {
        mouseButtonMask: 2,
      });

      this.cornerstoneTools.setToolActiveForElement(element, 'StackScrollMouseWheel', {
        mouseButtonMask: 3,
      });
      this.cornerstoneTools.setToolActiveForElement(element, 'Wwwc', { mouseButtonMask: 1 });
      //
      this.cornerstoneTools.setToolPassiveForElement(element, 'BoxLabel', {
        mouseButtonMask: 1,
      });

      element.addEventListener('mouseenter', (event) => {
        element.focus();
      });

      element.addEventListener('keydown', (event) => {
        switch (event.key) {
          case 'b': {
            this.setState({ activeToolName: 'BoxLabel' });
            break;
          }
          case 'w': {
            this.setState({ activeToolName: 'Wwwc' });
            break;
          }
          case 'r': {
            this.setState({ activeToolName: 'WwwcRegion' });
            break;
          }
          case 'l': {
            this.setState({ activeToolName: 'Length' });
            break;
          }
          case 's': {
            this.props.synchronizerPosition.enabled = !this.props.synchronizerPosition.enabled;
            break;
          }
          case 't': {
            this.setState({ activeBorder: !this.state.activeBorder});
            if (this.state.activeBorder){
              this.cornerstoneTools.setToolEnabledForElement(element, 'BorderNoLabel');
              this.cornerstone.updateImage(element)
            } else {
              this.cornerstoneTools.setToolDisabledForElement(element,'BorderNoLabel');
              this.cornerstone.updateImage(element)
            }
            break;
          }
          case ' ': {
            switch (this.state.activeToolName) {
              case 'Wwwc':
                this.setState({ activeToolName: 'BoxLabel' });
                break;
              case 'BoxLabel':
                this.setState({ activeToolName: 'Length' });
                break;
              case 'Length':
                this.setState({ activeToolName: 'Wwwc' });
                break;
              default:
                this.setState({ activeToolName: 'Wwwc' });
                break;
            }
            break;
          }
          default: {
            break;
          }
        }
      });

      this.eventHandlerData.forEach((data) => {
        const { eventTarget, eventType, handler } = data;

        eventTarget.addEventListener(eventType, handler);
      });
    }

    const imagePromise = this.cornerstone.loadAndCacheImage(imageIds[currentImageIndex]);

    // Load the first image in the stack
    imagePromise.then(
      (image) => {
        try {
          this.cornerstone.getEnabledElement(element);
        } catch (error) {
          // Handle cases where the user ends the session before the image is displayed.
          console.error(error);
          return;
        }

        // Set Soft Tissue preset for all images by default
        const viewport = this.cornerstone.getDefaultViewportForImage(element, image);
        viewport.voi = {
          windowWidth:
            (image.windowWidth && image.windowWidth.length && image.windowWidth[0]) || 400,
          windowCenter:
            (image.windowCenter && image.windowCenter.length && image.windowCenter[0]) || 40,
        };

        // Display the first image
        this.cornerstone.displayImage(element, image, viewport);

        this.props.synchronizer.getSourceElements().forEach(e=> this.cornerstone.updateImage(e, true))
        this.props.synchronizer.add(element)
        this.props.synchronizerPosition.add(element)
        this.cornerstoneTools.setToolEnabledForElement(element, 'ReferenceLines', {
          synchronizationContext: this.props.synchronizer,
       })
        const dispose = autorun(() => {
          if (this.props.labelStore.activeLabelUUID !== '') {
            this.cornerstone.updateImage(this.element);
          }
          if (this.props.labelStore.labels.length !== undefined) {
            this.cornerstone.updateImage(this.element);
          }
        });
        this.element.addEventListener('cornerstoneelementdisabled', dispose);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  componentWillUnmount() {
    this.eventHandlerData.forEach((data) => {
      const { eventTarget, eventType, handler } = data;
      eventTarget.removeEventListener(eventType, handler);
    });

    const element = this.element;
    this.cornerstoneTools.clearToolState(element, 'stackPrefetch');
    this.cornerstone.disable(element);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.currentImageIndex !== prevState.currentImageIndex) {
      this.props.updateImageIndexCallback(this.state.currentImageIndex);
      this.scrollToIndex(this.element, this.state.currentImageIndex);
    }

    if (this.state.activeToolName !== prevState.activeToolName) {
      this.cornerstoneTools.setToolActiveForElement(this.element, this.state.activeToolName, {
        mouseButtonMask: 1,
      });
    }
  }
}

export default withUserStore(withLabelStore(observer(CornerstoneViewport)));
