import React from 'react';
import {
  EuiFieldText,
  EuiFormRow,
  EuiFieldPassword,
  EuiIcon,
  EuiForm,
  EuiButton,
  EuiCard,
  EuiFlexItem,
  EuiFlexGroup,
} from '@elastic/eui';

class SignIn extends React.Component {
  componentDidMount() {
    fetch(`/api/userinfo`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.email) {
          this.props.history.push('/');
        }
      })
      .catch(() => {});
  }

  render() {
    return (
      <>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem></EuiFlexItem>
          <EuiFlexItem grow={false} style={{ minWidth: 300 }}>
            <EuiCard icon={<EuiIcon type="lock" size="l" color="#DDD" />} title="" description=''>
              <EuiForm component="form" method="post" action="/api/login">
                <EuiFormRow label="email" fullWidth>
                  <EuiFieldText name="email" fullWidth />
                </EuiFormRow>
                <EuiFormRow label="password" fullWidth>
                  <EuiFieldPassword name="password" fullWidth />
                </EuiFormRow>
                <EuiButton type="submit">sign in</EuiButton>
              </EuiForm>
            </EuiCard>
          </EuiFlexItem>
          <EuiFlexItem></EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }
}

export default SignIn;
