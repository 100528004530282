import React from 'react';
import PropTypes from 'prop-types';

const viewportStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  borderRadius: 'inherit',
  overflow: 'hidden',
};

const viewportElementStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '0',
  backgroundColor: 'black',
  outline: '0 !important',
  borderRadius: 'inherit',
  zIndex: '0',
};

class Thumbnail extends React.Component {
  static propTypes = {
    cornerstone: PropTypes.object.isRequired,
    children: PropTypes.node,
    imageId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.cornerstone = props.cornerstone;
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div style={viewportStyle}>
        <div
          onContextMenu={this.onContextMenu}
          style={viewportElementStyle}
          ref={(input) => {
            this.element = input;
          }}
        >
          <canvas
            className="cornerstone-canvas"
            // style={{ position: 'absolute', top: '0', height: '100%' }}
          />
        </div>
        {this.props.children}
      </div>
    );
  }

  onContextMenu = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    const element = this.element;
    this.cornerstone.enable(element, this.props.cornerstoneOptions);

    this.cornerstone
      .loadAndCacheImage(this.props.imageId)
      .then((image) => {
        const viewport = this.cornerstone.getDefaultViewportForImage(element, image);
        viewport.scale *= 1.15;
        viewport.translation.x = 15;
        viewport.translation.y = 5;
        viewport.voi = {
          windowWidth:
            (image.windowWidth && image.windowWidth.length && image.windowWidth[0]) || 400,
          windowCenter:
            (image.windowCenter && image.windowCenter.length && image.windowCenter[0]) || 40,
        };
        this.cornerstone.displayImage(element, image, viewport);
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    const element = this.element;
    this.cornerstone.disable(element);
  }
}

export default Thumbnail;
