import cornerstoneTools from 'cornerstone-tools';
import cornerstone from 'cornerstone-core';
import { vec2, vec3 } from 'gl-matrix';
import Obb from './Obb';

const BaseTool = cornerstoneTools.import('base/BaseTool');
const drawCircle = cornerstoneTools.import('drawing/drawCircle');
const drawLine = cornerstoneTools.import('drawing/drawLine');
const getNewContext = cornerstoneTools.import('drawing/getNewContext');
const MouseCursor = cornerstoneTools.import('tools/cursors/MouseCursor');

const svgCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4830.3,4956.3C2891.5,4079,423.1,2941.9,402.6,2915.3c-79.8-106.3-40.9-229.1,96.1-298.6c79.8-40.9,1167.8-537.9,3891.8-1779.2c310.8-141.1,584.9-257.7,609.4-257.7c22.5,0,292.5,114.5,599.2,253.6c4390.8,1998.1,3988,1809.9,4020.7,1889.7c28.6,69.5,20.4,135-22.5,192.2c-28.6,36.8-4489,2077.8-4576.9,2094.2C4989.8,5013.6,4912.1,4993.1,4830.3,4956.3z"/><path d="M363.7,2228.1l-57.3-49.1V-318c0-2468.5,0-2497.1,40.9-2535.9c22.5-22.5,989.8-466.3,2147.4-985.7c1562.5-701.5,2126.9-946.9,2186.2-946.9c61.4,0,87.9,12.3,126.8,55.2l49.1,57.3v2480.7c0,2333.5-2.1,2484.8-34.8,2525.7c-47,57.3-4259.9,1944.9-4341.8,1944.9C447.6,2277.2,396.5,2254.7,363.7,2228.1z"/><path d="M9294.7,2205.6c-83.8-38.9-1036.9-466.3-2116.7-951C6002.1,729.1,5200.4,358.9,5180,332.3c-34.8-40.9-36.8-192.2-36.8-2523.7c0-2454.1,0-2480.7,40.9-2531.8c71.6-92,139.1-90,372.2,12.3C6486.8-4308,9619.9-2886.7,9652.6-2854c40.9,38.9,40.9,65.5,40.9,2535.9v2497.1l-57.3,49.1C9558.5,2295.6,9478.8,2289.5,9294.7,2205.6z"/></g>`,
  {
    viewBox: {
      x: 1000,
      y: 1000,
    },
  }
);

const getVerticesId = (v) => v && `${v.labeluuid}${v.index}`;

const patient2image = (geometry, p) => {
  const { rowVec, colVec, ipp, spacing } = geometry;
  const AB = vec3.sub(vec3.create(), p, ipp);
  return vec2.fromValues(vec3.dot(rowVec, AB) / spacing[1], vec3.dot(colVec, AB) / spacing[0]);
};

const image2patient = (geometry, p) => {
  const { rowVec, colVec, ipp, spacing } = geometry;
  const deltaX = vec3.scale(vec3.create(), rowVec, p[0] * spacing[0]);
  const deltaY = vec3.scale(vec3.create(), colVec, p[1] * spacing[1]);
  const delta = vec3.add(vec3.create(), deltaX, deltaY);
  return vec3.add(vec3.create(), ipp, delta);
};

const getClosestVertices = (vertices, threshold) => {
  const closest = vertices.reduce((acc, cur) => (cur.distance < acc.distance ? cur : acc), {
    distance: 9999,
  });

  if (closest.distance < threshold) {
    return closest;
  }
};

const isActive = (element) => {
  return cornerstoneTools.getToolForElement(element, 'BoxLabel').mode === 'active';
};

const projectImageDeltaToObb = (imageDelta, obb, geometry, vertices) => {
  const { rowVec, colVec, spacing } = geometry;
  const scaledDelta = vec2.multiply(vec2.create(), imageDelta, spacing);
  const patientSpaceRowDelta = vec3.scale(vec3.create(), rowVec, scaledDelta[0]);
  const patientSpaceColDelta = vec3.scale(vec3.create(), colVec, scaledDelta[1]);
  const patientSpaceDelta = vec3.add(vec3.create(), patientSpaceRowDelta, patientSpaceColDelta);
  return projectPatientDeltaToObb(patientSpaceDelta, obb, vertices);
};

const projectPatientDeltaToObb = (patientSpaceDelta, obb, vertices) => {
  const center = vec3.clone(obb.center);
  const axis = obb.axis.map((a) => vec3.clone(a));
  const halfWidth = vec3.clone(obb.halfWidth);

  const VC = vec3.sub(vec3.create(), vertices, obb.center);

  // half width is in the obb.axis referential
  const projectionOnObbAxis = obb.axis.map((a) => vec3.dot(a, patientSpaceDelta));

  const newHalfWidth = vec3.create();

  for (let i = 0; i < 3; i++) {
    const sign = vec3.dot(obb.axis[i], VC) < 0 ? -1 : 1;
    newHalfWidth[i] = halfWidth[i] + (sign * projectionOnObbAxis[i]) / 2;

    // bail if the rectangle would width would go below .99 (would be flipped)
    if (newHalfWidth[i] < 0.1) {
      return { center, axis, halfWidth };
    }
  }
  vec3.copy(halfWidth, newHalfWidth);
  vec3.scaleAndAdd(center, center, patientSpaceDelta, 1 / 2);

  return { center, axis, halfWidth };
};

const getToolState = (element) => {
  const toolstate = cornerstoneTools.getToolState(element, 'BoxLabel');
  if (!toolstate || !toolstate.data || !toolstate.data.length) {
    return;
  }
  return toolstate.data[0];
};

const sort2DPoints = (points) => {
  const c = points.reduce((acc, cur) => {
    return vec2.scaleAndAdd(acc, acc, cur, 1 / points.length);
  }, vec2.create());

  let startAngle;
  return points
    .map((point) => {
      let angle = Math.atan2(point[1] - c[1], point[0] - c[0]);
      if (startAngle === undefined) {
        startAngle = angle;
      } else if (angle < startAngle) {
        angle += Math.PI * 2;
      }
      return { point, angle };
    })
    .sort((a, b) => a.angle - b.angle)
    .map((wrapped) => wrapped.point);
};

class DrawBoxTool extends BaseTool {
  constructor(props) {
    const defaultProps = {
      name: 'BoxLabel',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor,
    };
    super(props, defaultProps);
  }

  renderToolData(evt) {
    const { canvasContext, element, enabledElement } = evt.detail;
    const toolstate = getToolState(element);
    if (!toolstate) {
      return;
    }

    const context = getNewContext(canvasContext.canvas);
    const { labelStore, activeVertices } = toolstate;

    if (!labelStore || !labelStore.labels || !labelStore.labels.length) {
      return;
    }

    if (activeVertices && !isActive(element)) {
      toolstate.activeVertices = undefined;
    }

    const image = enabledElement.image.metadata;

    // to draw active index last
    const labelIndices = [...Array(labelStore.labels.length).keys()];
    const activeLabelIndex = labelStore.labels.findIndex(
      (w) => w.label.labeluuid === labelStore.activeLabelUUID
    );
    if (activeLabelIndex !== -1) {
      labelIndices.splice(activeLabelIndex, 1);
      labelIndices.push(activeLabelIndex);
    }

    labelIndices.forEach((i) => {
      const w = labelStore.labels[i];
      if (w.label.type !== 'forobb' || !image.foruuid || w.label.payload.foruuid !== image.foruuid)
        return;

      const points = w.obb.imageIntersection([image])[0];
      if (points.length < 3) {
        return;
      }

      const imagePlanePoint = points.map((p) => patient2image(image.geometry, p));
      const sortedImagePlanePoint = sort2DPoints(imagePlanePoint);
      const isActiveLabel = labelStore.activeLabelUUID === w.label.labeluuid;
      const obbColor = w.color + (isActiveLabel ? 'ff' : '55');

      const { close, far } = w.obb.getPlaneCutEdges(image);
      close.forEach((e) => {
        const p1 = patient2image(image.geometry, e[0]);
        const p2 = patient2image(image.geometry, e[1]);
        drawLine(
          context,
          element,
          { x: p1[0], y: p1[1] },
          { x: p2[0], y: p2[1] },
          {
            color: obbColor,
            lineWidth: 1,
          }
        );
      });
      far.forEach((e) => {
        const p1 = patient2image(image.geometry, e[0]);
        const p2 = patient2image(image.geometry, e[1]);
        drawLine(
          context,
          element,
          { x: p1[0], y: p1[1] },
          { x: p2[0], y: p2[1] },
          {
            color: obbColor,
            lineWidth: 1,
            lineDash: [2, 5],
          }
        );
      });

      // current plane proj
      for (let i = 0, len = sortedImagePlanePoint.length; i < len; i++) {
        const p1 = sortedImagePlanePoint[i];
        const p2 = sortedImagePlanePoint[(i + 1) % len];
        drawLine(
          context,
          element,
          { x: p1[0], y: p1[1] },
          { x: p2[0], y: p2[1] },
          {
            color: obbColor,
            lineWidth: 3,
          }
        );
      }

      if (activeVertices) {
        const [x, y] = activeVertices.imagePosition;
        labelStore.activePoint = cornerstone.pixelToCanvas(evt.detail.element, { x, y });

        const p = activeVertices.imagePosition;
        drawCircle(context, element, { x: p[0], y: p[1] }, 5, {
          color: activeVertices.color + '55',
        });
      } else {
        labelStore.activePoint = undefined;
      }
    });
  }

  mouseMoveCallback(evt) {
    if (!isActive(evt.detail.element)) {
      return;
    }

    const toolstate = getToolState(evt.detail.element);
    if (!toolstate) {
      return;
    }
    const { labelStore } = toolstate;

    const { x: mousex, y: mousey } = evt.detail.currentPoints.image;
    const image = evt.detail.image.metadata;

    const imagePos = vec2.fromValues(mousex, mousey);

    const vertices = [];
    labelStore.labels.forEach((w) => {
      const l = w.label;
      if (l.type !== 'forobb' || !image.foruuid || l.payload.foruuid !== image.foruuid) return;
      if (w.obb.planeIntersection(image.geometry.plane).length < 3) return;

      w.obb.vertices.forEach((v, i) => {
        const imagePosition = patient2image(image.geometry, v);
        vertices.push({
          distance: vec2.distance(imagePos, imagePosition),
          imagePosition,
          patientPosition: v,
          index: i,
          labeluuid: l.labeluuid,
          color: w.color,
        });
      });
    });
    if (vertices.length === 0) {
      return;
    }

    const oldVertices = getVerticesId(toolstate.activeVertices);
    toolstate.activeVertices = undefined;

    // to prioritize active index
    if (labelStore.activeLabelUUID) {
      toolstate.activeVertices = getClosestVertices(
        vertices.filter((v) => v.labeluuid === labelStore.activeLabelUUID),
        8
      );
    }

    if (!toolstate.activeVertices) {
      toolstate.activeVertices = getClosestVertices(vertices, 10);
    }

    if (oldVertices !== getVerticesId(toolstate.activeVertices)) {
      cornerstone.updateImage(evt.detail.element);
    }
  }

  preMouseDownCallback(evt) {
    const toolstate = getToolState(evt.detail.element);
    if (!toolstate) {
      return;
    }
    const { labelStore, userStore, activeVertices } = toolstate;

    if (activeVertices){
      labelStore.activeLabelUUID = activeVertices.labeluuid;
    }

    if (activeVertices && labelStore.activeLabelUUID) {
      return;
    }

    const image = evt.detail.image;
    const metadata = image.metadata;
    const geometry = metadata.geometry;

    if (evt.detail.event.ctrlKey) {
      // bring obb to this slice
      const w = labelStore.activeLabelWrapped;
      if (
        !w ||
        w.label.type !== 'forobb' ||
        !metadata.foruuid ||
        w.label.payload.foruuid !== metadata.foruuid
      ) {
        return;
      }

      if (w.obb.planeIntersection(geometry.plane).length > 3) {
        //label already intersect plane
        return;
      }
      const vertices = w.obb.vertices
        .map((patientPosition, index) => {
          const distance = vec3.dot(
            vec3.sub(vec3.create(), geometry.ipp, patientPosition),
            geometry.depthVec
          );
          return { patientPosition, distance, index };
        })
        .sort((a, b) => Math.abs(a.distance) - Math.abs(b.distance))
        .slice(0, 4);

      const averageDistance = vertices.reduce((acc, cur) => acc + cur.distance / 4, 0);
      const patientDelta = vec3.scale(
        vec3.create(),
        geometry.depthVec,
        averageDistance + (averageDistance < 0 ? -0.5 : 0.5)
      );
      const newPayloadObb = projectPatientDeltaToObb(
        patientDelta,
        w.label.payload.obb,
        vertices[0].patientPosition
      );
      w.label.payload.obb = newPayloadObb;
      w.obb = new Obb(newPayloadObb.center, newPayloadObb.axis, newPayloadObb.halfWidth);
      w.committed = false;
      cornerstone.updateImage(evt.detail.element);
      return;
    } else {
      // create new label
      const imagePosition = vec2.fromValues(
        evt.detail.currentPoints.image.x,
        evt.detail.currentPoints.image.y
      );
      const center = image2patient(geometry, imagePosition);

      const seriesuuid = image.metadata.seriesuuid;
      const foruuid = image.metadata.foruuid;
      const setudyuuid = image.metadata.studyuuid;
      const useruuid = userStore.useruuid;
      const payload = {
        foruuid,
        obb: {
          center,
          axis: [
            vec3.clone(geometry.rowVec),
            vec3.clone(geometry.colVec),
            vec3.clone(geometry.depthVec),
          ],
          halfWidth: [0.1, 0.1, 0.2],
        },
      };
      const createdLabel = labelStore.createLabel(
        'forobb',
        '',
        payload,
        useruuid,
        undefined,
        seriesuuid,
        setudyuuid
      );

      const index = 0;
      toolstate.activeVertices = {
        labeluuid: createdLabel.label.labeluuid,
        patientPosition: createdLabel.obb.vertices[index],
        imagePosition: patient2image(geometry, createdLabel.obb.vertices[index]),
        color: createdLabel.color,
        index,
      };
    }
  }

  mouseDragCallback(evt) {
    const toolstate = getToolState(evt.detail.element);
    if (!toolstate) {
      return;
    }
    const { labelStore, activeVertices } = toolstate;

    if (!activeVertices) {
      return;
    }

    const { x: deltax, y: deltay } = evt.detail.deltaPoints.image;
    if (deltax === 0 && deltay === 0) return;

    const image = evt.detail.image.metadata;

    const wrappedLabel = labelStore.labels.find(
      (l) => l.label.labeluuid === activeVertices.labeluuid
    );

    if (!wrappedLabel) return;

    const label = wrappedLabel.label;

    const deltaImage = vec2.fromValues(deltax, deltay);
    const newPayloadObb = projectImageDeltaToObb(
      deltaImage,
      label.payload.obb,
      image.geometry,
      activeVertices.patientPosition
    );
    label.payload.obb = newPayloadObb;
    wrappedLabel.obb = new Obb(newPayloadObb.center, newPayloadObb.axis, newPayloadObb.halfWidth);
    wrappedLabel.committed = false;

    activeVertices.patientPosition = wrappedLabel.obb.vertices[activeVertices.index];
    activeVertices.distance = undefined;
    activeVertices.imagePosition = patient2image(image.geometry, activeVertices.patientPosition);

    cornerstone.updateImage(evt.detail.element);
  }

  newImageCallback(evt) {
    const toolstate = getToolState(evt.detail.element);
    if (!toolstate) {
      return;
    }
    toolstate.activeVertices = undefined;
  }
}

export { DrawBoxTool, DrawBoxTool as default };
