/* eslint-disable */
'use strict';

var $protobuf = require('protobufjs/minimal');

// Common aliases
var $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

$root.ImageMessage = (function () {
  /**
   * Properties of an ImageMessage.
   * @exports IImageMessage
   * @interface IImageMessage
   * @property {number|null} [width] ImageMessage width
   * @property {number|null} [height] ImageMessage height
   * @property {Uint8Array|null} [pixelData] ImageMessage pixelData
   * @property {string|null} [url] ImageMessage url
   */

  /**
   * Constructs a new ImageMessage.
   * @exports ImageMessage
   * @classdesc Represents an ImageMessage.
   * @implements IImageMessage
   * @constructor
   * @param {IImageMessage=} [properties] Properties to set
   */
  function ImageMessage(properties) {
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * ImageMessage width.
   * @member {number} width
   * @memberof ImageMessage
   * @instance
   */
  ImageMessage.prototype.width = 0;

  /**
   * ImageMessage height.
   * @member {number} height
   * @memberof ImageMessage
   * @instance
   */
  ImageMessage.prototype.height = 0;

  /**
   * ImageMessage pixelData.
   * @member {Uint8Array} pixelData
   * @memberof ImageMessage
   * @instance
   */
  ImageMessage.prototype.pixelData = $util.newBuffer([]);

  /**
   * ImageMessage url.
   * @member {string} url
   * @memberof ImageMessage
   * @instance
   */
  ImageMessage.prototype.url = '';

  /**
   * Creates a new ImageMessage instance using the specified properties.
   * @function create
   * @memberof ImageMessage
   * @static
   * @param {IImageMessage=} [properties] Properties to set
   * @returns {ImageMessage} ImageMessage instance
   */
  ImageMessage.create = function create(properties) {
    return new ImageMessage(properties);
  };

  /**
   * Encodes the specified ImageMessage message. Does not implicitly {@link ImageMessage.verify|verify} messages.
   * @function encode
   * @memberof ImageMessage
   * @static
   * @param {IImageMessage} message ImageMessage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  ImageMessage.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.width != null && message.hasOwnProperty('width'))
      writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.width);
    if (message.height != null && message.hasOwnProperty('height'))
      writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.height);
    if (message.pixelData != null && message.hasOwnProperty('pixelData'))
      writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.pixelData);
    if (message.url != null && message.hasOwnProperty('url'))
      writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.url);
    return writer;
  };

  /**
   * Encodes the specified ImageMessage message, length delimited. Does not implicitly {@link ImageMessage.verify|verify} messages.
   * @function encodeDelimited
   * @memberof ImageMessage
   * @static
   * @param {IImageMessage} message ImageMessage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  ImageMessage.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes an ImageMessage message from the specified reader or buffer.
   * @function decode
   * @memberof ImageMessage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {ImageMessage} ImageMessage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  ImageMessage.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.ImageMessage();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.width = reader.uint32();
          break;
        case 2:
          message.height = reader.uint32();
          break;
        case 3:
          message.pixelData = reader.bytes();
          break;
        case 4:
          message.url = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes an ImageMessage message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof ImageMessage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {ImageMessage} ImageMessage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  ImageMessage.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies an ImageMessage message.
   * @function verify
   * @memberof ImageMessage
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  ImageMessage.verify = function verify(message) {
    if (typeof message !== 'object' || message === null) return 'object expected';
    if (message.width != null && message.hasOwnProperty('width'))
      if (!$util.isInteger(message.width)) return 'width: integer expected';
    if (message.height != null && message.hasOwnProperty('height'))
      if (!$util.isInteger(message.height)) return 'height: integer expected';
    if (message.pixelData != null && message.hasOwnProperty('pixelData'))
      if (
        !(
          (message.pixelData && typeof message.pixelData.length === 'number') ||
          $util.isString(message.pixelData)
        )
      )
        return 'pixelData: buffer expected';
    if (message.url != null && message.hasOwnProperty('url'))
      if (!$util.isString(message.url)) return 'url: string expected';
    return null;
  };

  /**
   * Creates an ImageMessage message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof ImageMessage
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {ImageMessage} ImageMessage
   */
  ImageMessage.fromObject = function fromObject(object) {
    if (object instanceof $root.ImageMessage) return object;
    var message = new $root.ImageMessage();
    if (object.width != null) message.width = object.width >>> 0;
    if (object.height != null) message.height = object.height >>> 0;
    if (object.pixelData != null)
      if (typeof object.pixelData === 'string')
        $util.base64.decode(
          object.pixelData,
          (message.pixelData = $util.newBuffer($util.base64.length(object.pixelData))),
          0
        );
      else if (object.pixelData.length) message.pixelData = object.pixelData;
    if (object.url != null) message.url = String(object.url);
    return message;
  };

  /**
   * Creates a plain object from an ImageMessage message. Also converts values to other types if specified.
   * @function toObject
   * @memberof ImageMessage
   * @static
   * @param {ImageMessage} message ImageMessage
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  ImageMessage.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.defaults) {
      object.width = 0;
      object.height = 0;
      if (options.bytes === String) object.pixelData = '';
      else {
        object.pixelData = [];
        if (options.bytes !== Array) object.pixelData = $util.newBuffer(object.pixelData);
      }
      object.url = '';
    }
    if (message.width != null && message.hasOwnProperty('width')) object.width = message.width;
    if (message.height != null && message.hasOwnProperty('height')) object.height = message.height;
    if (message.pixelData != null && message.hasOwnProperty('pixelData'))
      object.pixelData =
        options.bytes === String
          ? $util.base64.encode(message.pixelData, 0, message.pixelData.length)
          : options.bytes === Array
          ? Array.prototype.slice.call(message.pixelData)
          : message.pixelData;
    if (message.url != null && message.hasOwnProperty('url')) object.url = message.url;
    return object;
  };

  /**
   * Converts this ImageMessage to JSON.
   * @function toJSON
   * @memberof ImageMessage
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  ImageMessage.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  return ImageMessage;
})();

$root.ImageRequestMessage = (function () {
  /**
   * Properties of an ImageRequestMessage.
   * @exports IImageRequestMessage
   * @interface IImageRequestMessage
   * @property {ImageRequestMessage.Op|null} [op] ImageRequestMessage op
   * @property {Array.<string>|null} [urls] ImageRequestMessage urls
   */

  /**
   * Constructs a new ImageRequestMessage.
   * @exports ImageRequestMessage
   * @classdesc Represents an ImageRequestMessage.
   * @implements IImageRequestMessage
   * @constructor
   * @param {IImageRequestMessage=} [properties] Properties to set
   */
  function ImageRequestMessage(properties) {
    this.urls = [];
    if (properties)
      for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
        if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
  }

  /**
   * ImageRequestMessage op.
   * @member {ImageRequestMessage.Op} op
   * @memberof ImageRequestMessage
   * @instance
   */
  ImageRequestMessage.prototype.op = 0;

  /**
   * ImageRequestMessage urls.
   * @member {Array.<string>} urls
   * @memberof ImageRequestMessage
   * @instance
   */
  ImageRequestMessage.prototype.urls = $util.emptyArray;

  /**
   * Creates a new ImageRequestMessage instance using the specified properties.
   * @function create
   * @memberof ImageRequestMessage
   * @static
   * @param {IImageRequestMessage=} [properties] Properties to set
   * @returns {ImageRequestMessage} ImageRequestMessage instance
   */
  ImageRequestMessage.create = function create(properties) {
    return new ImageRequestMessage(properties);
  };

  /**
   * Encodes the specified ImageRequestMessage message. Does not implicitly {@link ImageRequestMessage.verify|verify} messages.
   * @function encode
   * @memberof ImageRequestMessage
   * @static
   * @param {IImageRequestMessage} message ImageRequestMessage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  ImageRequestMessage.encode = function encode(message, writer) {
    if (!writer) writer = $Writer.create();
    if (message.op != null && message.hasOwnProperty('op'))
      writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.op);
    if (message.urls != null && message.urls.length)
      for (var i = 0; i < message.urls.length; ++i)
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.urls[i]);
    return writer;
  };

  /**
   * Encodes the specified ImageRequestMessage message, length delimited. Does not implicitly {@link ImageRequestMessage.verify|verify} messages.
   * @function encodeDelimited
   * @memberof ImageRequestMessage
   * @static
   * @param {IImageRequestMessage} message ImageRequestMessage message or plain object to encode
   * @param {$protobuf.Writer} [writer] Writer to encode to
   * @returns {$protobuf.Writer} Writer
   */
  ImageRequestMessage.encodeDelimited = function encodeDelimited(message, writer) {
    return this.encode(message, writer).ldelim();
  };

  /**
   * Decodes an ImageRequestMessage message from the specified reader or buffer.
   * @function decode
   * @memberof ImageRequestMessage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @param {number} [length] Message length if known beforehand
   * @returns {ImageRequestMessage} ImageRequestMessage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  ImageRequestMessage.decode = function decode(reader, length) {
    if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
    var end = length === undefined ? reader.len : reader.pos + length,
      message = new $root.ImageRequestMessage();
    while (reader.pos < end) {
      var tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.op = reader.int32();
          break;
        case 2:
          if (!(message.urls && message.urls.length)) message.urls = [];
          message.urls.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  };

  /**
   * Decodes an ImageRequestMessage message from the specified reader or buffer, length delimited.
   * @function decodeDelimited
   * @memberof ImageRequestMessage
   * @static
   * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
   * @returns {ImageRequestMessage} ImageRequestMessage
   * @throws {Error} If the payload is not a reader or valid buffer
   * @throws {$protobuf.util.ProtocolError} If required fields are missing
   */
  ImageRequestMessage.decodeDelimited = function decodeDelimited(reader) {
    if (!(reader instanceof $Reader)) reader = new $Reader(reader);
    return this.decode(reader, reader.uint32());
  };

  /**
   * Verifies an ImageRequestMessage message.
   * @function verify
   * @memberof ImageRequestMessage
   * @static
   * @param {Object.<string,*>} message Plain object to verify
   * @returns {string|null} `null` if valid, otherwise the reason why it is not
   */
  ImageRequestMessage.verify = function verify(message) {
    if (typeof message !== 'object' || message === null) return 'object expected';
    if (message.op != null && message.hasOwnProperty('op'))
      switch (message.op) {
        default:
          return 'op: enum value expected';
        case 0:
          break;
      }
    if (message.urls != null && message.hasOwnProperty('urls')) {
      if (!Array.isArray(message.urls)) return 'urls: array expected';
      for (var i = 0; i < message.urls.length; ++i)
        if (!$util.isString(message.urls[i])) return 'urls: string[] expected';
    }
    return null;
  };

  /**
   * Creates an ImageRequestMessage message from a plain object. Also converts values to their respective internal types.
   * @function fromObject
   * @memberof ImageRequestMessage
   * @static
   * @param {Object.<string,*>} object Plain object
   * @returns {ImageRequestMessage} ImageRequestMessage
   */
  ImageRequestMessage.fromObject = function fromObject(object) {
    if (object instanceof $root.ImageRequestMessage) return object;
    var message = new $root.ImageRequestMessage();
    switch (object.op) {
      case 'ADD':
      case 0:
        message.op = 0;
        break;
    }
    if (object.urls) {
      if (!Array.isArray(object.urls)) throw TypeError('.ImageRequestMessage.urls: array expected');
      message.urls = [];
      for (var i = 0; i < object.urls.length; ++i) message.urls[i] = String(object.urls[i]);
    }
    return message;
  };

  /**
   * Creates a plain object from an ImageRequestMessage message. Also converts values to other types if specified.
   * @function toObject
   * @memberof ImageRequestMessage
   * @static
   * @param {ImageRequestMessage} message ImageRequestMessage
   * @param {$protobuf.IConversionOptions} [options] Conversion options
   * @returns {Object.<string,*>} Plain object
   */
  ImageRequestMessage.toObject = function toObject(message, options) {
    if (!options) options = {};
    var object = {};
    if (options.arrays || options.defaults) object.urls = [];
    if (options.defaults) object.op = options.enums === String ? 'ADD' : 0;
    if (message.op != null && message.hasOwnProperty('op'))
      object.op = options.enums === String ? $root.ImageRequestMessage.Op[message.op] : message.op;
    if (message.urls && message.urls.length) {
      object.urls = [];
      for (var j = 0; j < message.urls.length; ++j) object.urls[j] = message.urls[j];
    }
    return object;
  };

  /**
   * Converts this ImageRequestMessage to JSON.
   * @function toJSON
   * @memberof ImageRequestMessage
   * @instance
   * @returns {Object.<string,*>} JSON object
   */
  ImageRequestMessage.prototype.toJSON = function toJSON() {
    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
  };

  /**
   * Op enum.
   * @name ImageRequestMessage.Op
   * @enum {string}
   * @property {number} ADD=0 ADD value
   */
  ImageRequestMessage.Op = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = 'ADD')] = 0;
    return values;
  })();

  return ImageRequestMessage;
})();

module.exports = $root;
