import React from 'react';
import { observable, computed, action, runInAction, decorate, toJS } from 'mobx';
import colors from '../lib/colors';
import Obb from '../lib/Obb';
import { v1 as uuid } from 'uuid'
const { vec3 } = require('gl-matrix');

let colorIndex = 0;

const bodyPartLabels = [
  'AbdmChst',
  'Abdomen',
  'Ankle',
  'Arm',
  'Breast',
  'Calf',
  'Chest',
  'CrvSpine',
  'Elbow',
  'Femur',
  'Fingers',
  'Foot',
  'Forearm',
  'Hand',
  'Head',
  'Hip',
  'Humerus',
  'Kidney',
  'Knee',
  'Liver',
  'Lung',
  'LumSpine',
  'Neck',
  'Pelvis',
  'Shoulder',
  'Thigh',
  'ThoSpine',
  'Tibia',
  'Toes',
  'Wrist',
];

const lateralityList = [
  'Left',
  'Right'
]

const fodLabels = [
  'Implant'
]

const qualityLabels = [
  'Artifacts',
  'Faded',
  'NoAnatomy',
  'Noise',
  'PostProcessed',
]

const findingLabels = [
  'AorticDissectionAscending',
  'AorticDissectionDescending',
  'AorticDissectionInfrarenal',
  'LungNodule',
  'PulmonaryEmbolism',
]

class ObservableLabelStore {
  labels = [];
  allowedLabels = []
  activeLabelUUID = undefined;
  pendingRequests = undefined;
  activePoint = undefined;

  constructor(){
    this.allowedLabels.push({
      label: 'Foreign Object',
      options: fodLabels.map(label => {return {label}})
    });
    this.allowedLabels.push({
      label: 'Body Parts',
      options: bodyPartLabels.map(label => {return {label}})
    });
    this.allowedLabels.push({
      label: 'Quality',
      options: qualityLabels.map(label => {return {label, color: 'danger'}})
    });
    this.allowedLabels.push({
      label: 'Laterality',
      options: lateralityList.map(label => {return {label, color: 'accent'}})
    });
    this.allowedLabels.push({
      label: 'Findings',
      options: findingLabels.map(label => {return {label, color: 'accent'}})
    });
  }

  get activeLabel() {
    const activeLabelWrapped = this.activeLabelWrapped;
    if (activeLabelWrapped) {
      return activeLabelWrapped.label;
    }
    return undefined;
  }

  get activeLabelWrapped() {
    return this.labels.find((w) => w.label.labeluuid === this.activeLabelUUID);
  }

  get count() {
    return this.labels.length;
  }

  addLabel(label, committed = false) {
    const newLabel = {
      label,
      committed,
      pending: false,
      color: colors[colorIndex++ % colors.length],
      obb:
        label.type === 'forobb'
          ? new Obb(label.payload.obb.center, label.payload.obb.axis, label.payload.obb.halfWidth)
          : undefined,
    };
    this.labels.push(newLabel);
    return newLabel;
  }

  createLabel(type, label, payload, useruuid, imageuuid, seriesuuid, studyuuid) {
    const labeluuid = uuid();
    const datemodified = null;
    const newLabel = this.addLabel({
      useruuid,
      labeluuid,
      label,
      payload,
      seriesuuid,
      imageuuid,
      studyuuid,
      type,
      datemodified,
    });
    this.activeLabelUUID = labeluuid;
    return newLabel;
  }

  async saveLabel(labeluuid) {
    const wrappedLabel = this.labels.find((w) => w.label.labeluuid === labeluuid);
    if (!wrappedLabel || wrappedLabel.committed || wrappedLabel.pending) return;
    wrappedLabel.pending = true;
    wrappedLabel.pendingSave = true;

    const response = await fetch(`/api/labels/${labeluuid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(wrappedLabel.label),
    });

    if (response.status === 200) {
      runInAction(() => {
        wrappedLabel.committed = true;
        wrappedLabel.pending = false;
        wrappedLabel.pendingSave = false;
      });
    }
  }

  async deleteLabel(labeluuid) {
    const wrappedLabel = this.labels.find((w) => w.label.labeluuid === labeluuid);
    if (!wrappedLabel || wrappedLabel.pending) return;
    wrappedLabel.pending = true;
    wrappedLabel.pendingDelete = true;
    if(this.activeLabelUUID === labeluuid){
      this.activeLabelUUID = undefined
    }


    const response = await fetch(`/api/labels/${labeluuid}`, { method: 'DELETE' });

    if (response.status === 200 || response.status === 500) {
      // needed to find again in case the order was changed in the meantime
      const index = this.labels.findIndex((w) => w.label.labeluuid === labeluuid);
      runInAction(() => {
        wrappedLabel.committed = true;
        wrappedLabel.pending = false;
        wrappedLabel.pendingDelete = false
        this.labels.splice(index, 1);
      });
    }
  }

  async fetchLabels(studyid) {
    const response = await fetch(`/api/labels/get/${studyid}`);
    if (response.status !== 200) return;
    const labels = await response.json();
    if (!labels && !labels.length) return;
    labels.forEach((l) => {
      // Fixup for TypedArray converted to json in the object  format {"0": 0.9, "1": 1, "2": 4}
      // Accessing with pObb.center[0] works, but vec.fromValues(...pObb.center) would fail.
      if (l.payload.obb) {
        const pObb = l.payload.obb;
        pObb.center = vec3.fromValues(pObb.center[0], pObb.center[1], pObb.center[2]);
        pObb.halfWidth = vec3.fromValues(pObb.halfWidth[0], pObb.halfWidth[1], pObb.halfWidth[2]);
        pObb.axis = [
          vec3.fromValues(pObb.axis[0][0], pObb.axis[0][1], pObb.axis[0][2]),
          vec3.fromValues(pObb.axis[1][0], pObb.axis[1][1], pObb.axis[1][2]),
          vec3.fromValues(pObb.axis[2][0], pObb.axis[2][1], pObb.axis[2][2]),
        ];
      }
    });
    runInAction(() => {
      this.labels.length = 0;
      labels.forEach((l) => {
        this.addLabel(l, true);
      });
    });
  }

  print() {
    console.log(toJS(this.labels));
  }
}

decorate(ObservableLabelStore, {
  labels: observable,
  pendingRequests: observable,
  activeLabelUUID: observable,
  activePoint: observable,
  fetchForLabels: action,
  saveLabel: action,
  deleteLabel: action,
  count: computed,
  activeLabelWrapped: computed,
  activeLabel: computed,
});

const observableTodoStore = new ObservableLabelStore();
const Context = React.createContext(observableTodoStore);
const LabelStoreProvider = Context.Provider;
const LabelStoreConsumer = Context.Consumer;

const withLabelStore = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <LabelStoreConsumer>
      {(store) => <Component {...props} labelStore={store} />}
    </LabelStoreConsumer>
  );
};

export { withLabelStore, withLabelStore as default, LabelStoreProvider };
