import React from 'react';
import { withRouter } from 'react-router-dom';
import Hammer from 'hammerjs';
import Viewport from './Viewport';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import SeriesList from './SeriesList';
import LabelList from './LabelList';
import signOut from '../lib/signOut';
import { fetchImage } from '../lib/websocket';
import { vec2, vec3 } from 'gl-matrix';
import Obb from '../lib/Obb';
import md5 from 'blueimp-md5';

import {
  EuiPanel,
  EuiFlexItem,
  EuiFlexGroup,
  EuiCollapsibleNav,
  EuiResizableContainer,
} from '@elastic/eui';

import withLabelStore from '../context/Labels';
import withUserStore from '../context/User';

const USE_WS = false;
const MAX_SIMULTANEOUS_REQUESTS = 30;
const DOWNLOAD_PREFIXES = ['dl1.', 'dl2.', 'dl3.'];

cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({ showSVGCursors: true });

// Set the tool font and font size
const fontFamily =
  'Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';
cornerstoneTools.textStyle.setFont(`10px ${fontFamily}`);

// Set the tool width
cornerstoneTools.toolStyle.setToolWidth(2);

// Set color for inactive tools
cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 255)');

// Set color for active tools
cornerstoneTools.toolColors.setActiveColor('#ffce7a');


function getImagePlane(image) {
  let absnormal = [0, 0, 0];

  // cross(a,b) = [ a2 * b3 - a3 * b2, a3 * b1 - a1 * b3, a1 * b2 - a2 * b1 ]
  absnormal[0] = Math.abs(image.imageorientationpatient[1] * image.imageorientationpatient[5] - image.imageorientationpatient[2] * image.imageorientationpatient[4]);
  absnormal[1] = Math.abs(image.imageorientationpatient[2] * image.imageorientationpatient[3] - image.imageorientationpatient[0] * image.imageorientationpatient[5]);
  absnormal[2] = Math.abs(image.imageorientationpatient[0] * image.imageorientationpatient[4] - image.imageorientationpatient[1] * image.imageorientationpatient[3]);
  if (absnormal[1] >= absnormal[0] && absnormal[1] >= absnormal[2]) {
    return "coronal";
  }
  else if (absnormal[0] >= absnormal[1] && absnormal[0] >= absnormal[2]) {
    return "sagittal";
  }
  else if (absnormal[2] >= absnormal[0] && absnormal[2] >= absnormal[1]) {
    return "axial";
  }
}


function findCoronalImageInd(images, start, end) {
  // binary search for coronal image in the series
  if (start > end) return undefined;
  let mid = Math.floor((start + end) / 2); 
  if (getImagePlane(images[mid])==="coronal") return mid;
  var ind = findCoronalImageInd(images, mid + 1, end);
  if (ind !== undefined) return ind;
  return findCoronalImageInd(images, start, mid - 1);
}


class Viewer extends React.Component {
  abortController = new AbortController();

  state = {
    studyMetadata: {},
    predictions: {},
    currentSeries: [],
    activeViewPort: 0,
  };

  initialImageIndexinLoc = undefined;
  imageIndex = [undefined, undefined];
  updateImageIndex = (viewport, newIndex) => {
    this.imageIndex[viewport] = newIndex;
  }

  metadataProvider = (type, imageId) => {
    const imageuuid = imageId.slice(33);
    const studyMetadata = this.state.studyMetadata;
    let image;
    let rowCos;
    let colCos;
    let imgPatientPos;
    let foruuid;
    let pixelSpacing = []
    for (let i = 0; i < studyMetadata.series.length; i++) {
      const series = studyMetadata.series[i];
      for (let j = 0; j < series.images.length; j++) {
        if (series.images[j].imageuuid === imageuuid) {
          image = series.images[j];
          break;
        }
        if (image) break;
      }
    }
    if (!image) return;
    if (type === 'imagePlaneModule') {
      if (image.imageorientationpatient == null) {
        rowCos = 0.0;
        colCos = 0.0;
      }
      else {
        rowCos = image.imageorientationpatient.slice(0, 3);
        colCos = image.imageorientationpatient.slice(3);
      }
      if (image.imagepositionpatient == null) {
        imgPatientPos = 0;
      }
      else {
        imgPatientPos = image.imagepositionpatient;
      }
      if (image.foruuid == null) {
        foruuid = 0;
      } else {
        foruuid = image.foruuid;
      }
      // Default value for pixel spacing is 1 for both rows and columns to account for base 1mm space between pixels and avoid computation errors for bounding boxes checks.
      if (image.pixelspacing == null) {
        pixelSpacing.push(1);
        pixelSpacing.push(1);
      } else {
        pixelSpacing.push(image.pixelspacing[0])
        pixelSpacing.push(image.pixelspacing[1])
      }

      return {
        rowCosines: rowCos,
        columnCosines: colCos,
        imagePositionPatient: imgPatientPos,
        frameOfReferenceUID: foruuid,
        columns: image.columns,
        rows: image.rows,
        rowPixelSpacing: pixelSpacing[0],
        columnPixelSpacing: pixelSpacing[1],
      };
    }
  };

  componentDidMount() {
    cornerstone.metaData.addProvider(this.metadataProvider);
    this.synchronizer = new cornerstoneTools.Synchronizer(
      'cornerstonenewimage',
      cornerstoneTools.updateImageSynchronizer
    );

    this.synchronizerPosition = new cornerstoneTools.Synchronizer(
      'cornerstonenewimage',
      cornerstoneTools.stackImagePositionSynchronizer
    );
    this.synchronizerPosition.enabled = false;


    const params = new URLSearchParams(this.props.location.search);
    // here
    const seriesid = params.get('series');
    const studyid = params.get('study');
    const imageid = params.get('image');

    let query;
    if (studyid) {
      query = `/api/studies/get/${studyid}`;
    } else if (seriesid) {
      query = `/api/studies/getFromSeries/${seriesid}`;
    } else if (imageid) {
      query = `/api/studies/getFromImage/${imageid}`;
    } else {
      throw new Error(`invalid params: ${params}`);
    }

    fetch(query, { signal: this.abortController.signal })
      .then((r) => (r.status === 401 ? signOut() : r.json()))
      .then((studyMetadata) => {
        if (!studyMetadata) return;
        if (!studyMetadata.series) {
          alert("No series in this study!");
          return;
        }
        this.props.setBreadCrumbs([
          { text: studyMetadata.series[0].modality },
          { text: studyMetadata.patientname },
          { text: studyMetadata.studydescription },
        ]);

        fetch(`/api/model/studyuuid/${studyMetadata.studyuuid}`, {
          signal: this.abortController.signal,
        })
          .then((r) => r.json())
          .then((predictions) => {
            const toUuid = (md5) => {
              return (
                md5.substring(0, 8) +
                '-' +
                md5.substring(8, 12) +
                '-' +
                md5.substring(12, 16) +
                '-' +
                md5.substring(16, 20) +
                '-' +
                md5.substring(20)
              ).toLowerCase();
            };

            for (const [key, value] of Object.entries(predictions)) {
              predictions[key] = value
                .map((i) => {
                  i.imageuuid = toUuid(md5(i.sopInstanceUID));
                  return i;
                });
            }
            this.setState({ predictions });
          });

        this.props.labelStore.fetchLabels(studyMetadata.studyuuid);
        studyMetadata.series = studyMetadata.series.filter((s) => s.images !== null);

        studyMetadata.series = studyMetadata.series.sort((a, b) => a.seriesnumber - b.seriesnumber);
        const imageMetadata = new Map();

        let coronalSeriesInd = -1;
        let sagitalSeriesInd = -1;
        let axialSeriesInd = -1;
        let localizerSeriesInd = -1;
        let seriesind = 0;

        studyMetadata.series.forEach((series) => {
          const sortedImageIds = [];
          const sortedImages = series.images.sort((a, b) => a.instancenumber - b.instancenumber);

          // find series orientation based on series description

          if (localizerSeriesInd === -1 && series.seriesdescription !== 'undefined' && /\bloc\b|scout|localizer/i.test(series.seriesdescription)) {
            localizerSeriesInd = seriesind;
            //set initial image in scout seriees to a coronal image
            this.initialImageIndexinLoc = findCoronalImageInd(sortedImages, 0, sortedImages.length - 1);
          }
          else if (axialSeriesInd === -1 && series.seriesdescription !== 'undefined' && /\bAX\b|AxiaL|axt1\b|axt2/i.test(series.seriesdescription)) {
            axialSeriesInd = seriesind;
          }
          else if (sagitalSeriesInd === -1 && series.seriesdescription !== 'undefined' && /\bsag\b|sagittal|sagt1|sagt2/i.test(series.seriesdescription)) {
            sagitalSeriesInd = seriesind;
          }
          else if (coronalSeriesInd === -1 && series.seriesdescription !== 'undefined' && /\bcor\b|coronal/i.test(series.seriesdescription)) {
            coronalSeriesInd = seriesind;
          }


          sortedImages.forEach((i) => {
            const imageId = `rawpixels://api/images/transcode/${i.imageuuid}`;
            sortedImageIds.push(imageId);

            const rowVec = !i.imageorientationpatient
              ? vec3.fromValues(0, 0, 1)
              : vec3.normalize(
                vec3.create(),
                vec3.fromValues(...i.imageorientationpatient.slice(0, 3))
              );

            const colVec = !i.imageorientationpatient
              ? vec3.fromValues(0, 1, 0)
              : vec3.normalize(
                vec3.create(),
                vec3.fromValues(...i.imageorientationpatient.slice(3, 6))
              );
            const depthVec = vec3.cross(vec3.create(), rowVec, colVec);
            const ipp = !i.imagepositionpatient
              ? vec3.fromValues(0, 0, 0)
              : vec3.fromValues(...i.imagepositionpatient);
            const spacing = !i.pixelspacing
              ? vec2.fromValues(1, 1)
              : vec2.fromValues(...i.pixelspacing);
            const plane = { normal: depthVec, distance: vec3.dot(depthVec, ipp) };

            i.geometry = {
              rowVec,
              colVec,
              depthVec,
              ipp,
              spacing,
              plane,
              obb:
                !i.imageorientationpatient || !i.imagepositionpatient
                  ? undefined
                  : Obb.fromImage(i),
            };
            i.studyuuid = studyMetadata.studyuuid;
            i.seriesuuid = series.seriesuuid;
            i.foruuid = series.foruuid;


            imageMetadata.set(imageId, i);
          });
          series.imageIds = sortedImageIds;
          seriesind++;
        });


        // find series orientation based on first image  orientation if series were not found based on description
        if (axialSeriesInd === -1 || (localizerSeriesInd === -1 && coronalSeriesInd === -1 && sagitalSeriesInd === -1)) {
          for (var i = 0; i < studyMetadata.series.length; i++) {

            var plane = getImagePlane(studyMetadata.series[i].images[0]);

            if (sagitalSeriesInd === -1 &&  plane==="sagittal") {
              sagitalSeriesInd = i; 

            }
            else if (coronalSeriesInd === -1 && plane==="coronal") {
              coronalSeriesInd = i; 

            }
            else if ((axialSeriesInd === -1 || axialSeriesInd === localizerSeriesInd) && plane==="axial") {

              axialSeriesInd = i; 

            }
          }

        }



        let currentSeries = [0, 0];

        if (studyMetadata.series.length > 1) {

          let leftViewportSeriesInd = axialSeriesInd !== -1 && axialSeriesInd !== localizerSeriesInd ? axialSeriesInd : 1;
          let rightViewportSeriesInd = localizerSeriesInd !== -1 ? localizerSeriesInd : (coronalSeriesInd !== -1 ? coronalSeriesInd : (sagitalSeriesInd !== -1 ? sagitalSeriesInd : leftViewportSeriesInd === 0 ? 1 : 0));
          currentSeries = [leftViewportSeriesInd, rightViewportSeriesInd];

        }



        let initialImageIndex = undefined;
        if (seriesid) {
          currentSeries[0] = studyMetadata.series.findIndex(
            (s) => s.seriesuuid === seriesid || s.seriesinstanceuid === seriesid
          );
        } else if (imageid) {
          currentSeries[0] = studyMetadata.series.findIndex((s) => {
            const imageIndex = s.images.findIndex((i) => i.imageuuid === imageid);
            if (imageIndex >= 0) {
              initialImageIndex = imageIndex;
              return true;
            }
            return false;
          });
        }
        this.setState({ studyMetadata, imageMetadata, currentSeries, initialImageIndex });
      });

    let dlCount = 0;
    cornerstone.registerImageLoader('rawpixels', (imageId) => {
      cornerstoneTools.stackPrefetch.setConfiguration({
        maxImagesToPrefetch: Infinity,
        preserveExistingPool: false,
        maxSimultaneousRequests: MAX_SIMULTANEOUS_REQUESTS,
      });
      return {
        promise: (async () => {
          const path = imageId.substring(11);

          let metadataPromise = undefined;
          if (this.state.imageMetadata.has(imageId)) {
            metadataPromise = Promise.resolve(this.state.imageMetadata.get(imageId));
          } else {
            const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
            const uuid = imageId.match(uuidRegex)[0];
            metadataPromise = fetch(`/api/images/metadata/${uuid}`, {
              signal: this.abortController.signal,
            }).then((r) => r.json());
            metadataPromise.then((metadata) => {
              this.state.imageMetadata.set(imageId, metadata);
            });
          }

          let pixelDataResponsePromise = undefined;
          if (USE_WS) {
            pixelDataResponsePromise = fetchImage(path, {
              signal: this.abortController.signal,
            }).then((r) => r.buffer);
          } else {
            const prefix = DOWNLOAD_PREFIXES[dlCount++ % DOWNLOAD_PREFIXES.length];
            const url = `${window.location.protocol}//${prefix}${window.location.host}${path}`;
            pixelDataResponsePromise = fetch(url, {
              signal: this.abortController.signal,
              credentials: 'include',
            }).then((r) => r.arrayBuffer());
          }

          const [metadata, responseArrayBuffer] = await Promise.all([
            metadataPromise,
            pixelDataResponsePromise,
          ]);
          const BufferType = metadata.pixelrepresentation === 1 ? Int16Array : Uint16Array;
          const pixelData = new BufferType(responseArrayBuffer);

          let minPixelValue = 65535;
          let maxPixelValue = 0;

          for (let i = 0, l = pixelData.length, v = 0; i < l; v = pixelData[i++]) {
            if (minPixelValue > v) {
              minPixelValue = v;
            }
            if (maxPixelValue < v) {
              maxPixelValue = v;
            }
          }

          return {
            imageId: imageId,
            minPixelValue,
            maxPixelValue,
            instanceNumber: metadata.instancenumber,
            slope: metadata.rescaleslope || 1,
            intercept: metadata.rescaleintercept || 0,
            windowCenter: metadata.windowcenter,
            windowWidth: metadata.windowwidth,
            getPixelData: () => pixelData,
            rows: metadata.rows,
            columns: metadata.columns,
            height: metadata.rows,
            width: metadata.columns,
            color: false,
            columnPixelSpacing:
              metadata.pixelspacing && metadata.pixelspacing[0] ? metadata.pixelspacing[0] : 1,
            rowPixelSpacing:
              metadata.pixelspacing && metadata.pixelspacing[1] ? metadata.pixelspacing[1] : 1,
            sizeInBytes: metadata.rows * metadata.columns * 2,
            metadata,
          };
        })(),
      };
    });
  }

  componentWillUnmount() {
    cornerstone.metaData.removeProvider(this.metadataProvider);
    this.abortController.abort();
  }

  onSeriesClickedCallback = (index, destViewPort) => {
    if (index !== this.state.currentSeries[destViewPort]) {
      this.abortController.abort();
      this.abortController = new AbortController();
      const newCurrentSeries = [...this.state.currentSeries]
      newCurrentSeries[destViewPort] = index
      this.setState({
        currentSeries: newCurrentSeries,
        initialImageIndex: undefined,
      });
    }
  };

  onAddLabel = (type) => {
    const { activeViewPort, currentSeries, studyMetadata } = this.state;
    const { labelStore, userStore } = this.props;
    const { useruuid } = userStore;
    const activeSeriesIndex = currentSeries[activeViewPort];
    if (type === 'forobb') {
      const imageIndex = this.imageIndex[activeViewPort];
      const seriesuuid = studyMetadata.series[activeSeriesIndex].seriesuuid;
      const foruuid = studyMetadata.series[activeSeriesIndex].foruuid;
      const setudyuuid = studyMetadata.studyuuid;
      const metadata = studyMetadata.series[activeSeriesIndex].images[imageIndex]
      const obb = Obb.fromImage(metadata);
      const payload = {
        foruuid,
        obb: {
          center: obb.center,
          axis: obb.axis,
          halfWidth: obb.halfWidth,
        },
      };
      labelStore.createLabel(type, '', payload, useruuid, undefined, seriesuuid, setudyuuid);
    }

    if (type === 'range') {
      const seriesuuid = studyMetadata.series[activeSeriesIndex].seriesuuid;
      const setudyuuid = studyMetadata.studyuuid;
      const imageIndex = this.imageIndex[activeViewPort];
      labelStore.createLabel(type, '', { min: imageIndex, max: imageIndex }, useruuid, undefined, seriesuuid, setudyuuid);
    }
  };

  render() {
    const {
      currentSeries,
      studyMetadata,
      initialImageIndex,
      imageMetadata,
      activeViewPort,
      predictions,
    } = this.state;

    if (!studyMetadata.series) {
      return <div />;
    }


    return (
      <>
        <EuiCollapsibleNav isOpen={true} isDocked={true} style={{ animation: 'none' }}>
          <EuiResizableContainer direction="vertical" style={{ height: '100%' }}>
            {(EuiResizablePanel, EuiResizableButton) => (
              <>
                <EuiResizablePanel initialSize={60} minSize="20%">
                  <SeriesList
                    currentSeries={currentSeries}
                    studyMetadata={studyMetadata}
                    onSeriesChange={this.onSeriesClickedCallback}
                    predictions={predictions}
                  />
                </EuiResizablePanel>
                <EuiResizableButton size="s" className="navsplit" />
                <EuiResizablePanel initialSize={40} minSize="20%">
                  <LabelList
                    seriesuuid={studyMetadata.series[currentSeries[activeViewPort]].seriesuuid}
                    foruuid={studyMetadata.series[currentSeries[activeViewPort]].foruuid}
                    onAddLabel={this.onAddLabel}
                  />
                </EuiResizablePanel>
              </>
            )}
          </EuiResizableContainer>
        </EuiCollapsibleNav>
        {studyMetadata.series[currentSeries[0]].imageIds.length > 0 && (
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem>
              <EuiPanel
                paddingSize="none"
                onMouseDown={() => this.setState({ activeViewPort: 0 })}
                style={
                  this.state.activeViewPort === 0 ? { boxShadow: '0px 0px 3px #FFCE7A88' } : {}
                }
              >
                <Viewport
                  key={studyMetadata.series[currentSeries[0]].seriesuuid}
                  imageIds={studyMetadata.series[currentSeries[0]].imageIds}
                  cornerstone={cornerstone}
                  cornerstoneTools={cornerstoneTools}
                  studyMetadata={studyMetadata}
                  predictions={predictions}
                  imageMetadata={imageMetadata}
                  currentSeries={currentSeries[0]}
                  initialImageIndex={initialImageIndex}
                  onLabelPayloadUpdate={this.onLabelPayloadUpdate}
                  synchronizer={this.synchronizer}
                  synchronizerPosition={this.synchronizerPosition}
                  updateImageIndexCallback={this.updateImageIndex.bind(this, 0)}
                />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel
                paddingSize="none"
                onMouseDown={() => this.setState({ activeViewPort: 1 })}
                style={
                  this.state.activeViewPort === 1 ? { boxShadow: '0px 0px 3px #FFCE7A88' } : {}
                }
              >
                <Viewport
                  key={studyMetadata.series[currentSeries[1]].seriesuuid}
                  imageIds={studyMetadata.series[currentSeries[1]].imageIds}
                  cornerstone={cornerstone}
                  cornerstoneTools={cornerstoneTools}
                  studyMetadata={studyMetadata}
                  predictions={predictions}
                  imageMetadata={imageMetadata}
                  currentSeries={currentSeries[1]}
                  initialImageIndex={this.initialImageIndexinLoc}
                  onLabelPayloadUpdate={this.onLabelPayloadUpdate}
                  synchronizer={this.synchronizer}
                  synchronizerPosition={this.synchronizerPosition}
                  updateImageIndexCallback={this.updateImageIndex.bind(this, 1)}
                />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </>
    );
  }
}

export default withUserStore(withLabelStore(withRouter(Viewer)));
