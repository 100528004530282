import React from 'react';
import StudyList from '../components/StudyList';
import Viewer from '../components/Viewer';
import { Router, Route, Switch } from 'react-router-dom';
import SignIn from '../components/SignIn';
import history from '../components/history';
import signOut from '../lib/signOut';
import withUserStore from '../context/User';
import '@elastic/eui/dist/eui_theme_dark.css';
import './index.scss';

import {
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSectionItem,
  EuiAvatar,
  EuiHeaderSectionItemButton,
  EuiHeaderBreadcrumbs,
  EuiPopover,
  EuiContextMenu,
  EuiIcon,
  EuiPage,
  EuiPageBody,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiText,
  EuiTitle,
} from '@elastic/eui';

class App extends React.Component {
  openAccountPopover = () => {
    this.setState({ userMenuIsOpen: true });
  };

  closeAccountPopover = () => {
    this.setState({ userMenuIsOpen: false });
  };

  openFlyout = () => {
    this.setState({ isFlyoutVisible: true });
  };

  closeFlyout = () => {
    this.setState({ isFlyoutVisible: false });
  };

  setBreadCrumbs = (breadcrumbs) => {
    this.setState({ breadcrumbs });
  };

  constructor(props) {
    super(props);
    const breadcrumbs = [];
    this.state = { user: undefined, userMenuIsOpen: false, breadcrumbs, isFlyoutVisible: false };
    const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
      const [name, value] = cookie.split('=').map((c) => c.trim());
      cookies[name] = value;
      return cookies;
    }, {});
    if (cookies.token) {
      const user = JSON.parse(atob(cookies.token.split('.')[1]));
      if (user) {
        this.state.user = user;
        if (history.location.pathname === '/login') {
          history.push('/');
        }
      } else {
        signOut();
      }
    }
  }

  componentDidMount() {
    // fetch(`/api/userinfo`).then(r => r.status === 401 && signOut()).catch(console.log.error);
  }

  render() {
    const { userStore } = this.props;
    const { user, userMenuIsOpen, breadcrumbs, isFlyoutVisible } = this.state;
    userStore.useruuid = user && user.useruuid;
    const panels = [
      {
        id: 0,
        title: user && user.firstname && user.lastname ? user.firstname + ' ' + user.lastname : ' ',
        items: [
          {
            name: 'Sign Out',
            icon: <EuiIcon type="user" size="m" />,
            onClick: () => {
              signOut();
              this.setState({ user: undefined });
            },
          },
          {
            name: 'Preference',
            icon: <EuiIcon type="advancedSettingsApp" size="m" />,
            disabled: true,
          },
        ],
      },
    ];

    let flyout;
    if (isFlyoutVisible) {
      flyout = (
        <EuiFlyout onClose={this.closeFlyout} maxWidth={500} aria-labelledby="flyoutTitle">
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
              <h2>User Guide</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiText color="default">
              <h3>Series list</h3>
              <p>
                <b> Set left viewport series: </b> Left click on series card.
                <br />
                <b> Set right viewport series: </b> Right click on series card.
              </p>
              <h3>Basic image interactions</h3>
              <p>
                <b> Pan: </b> Middle mouse button drag.
                <br />
                <b> Zoom: </b> Right mouse button drag.
                <br />
                <b> Navigate next/prev image in stack: </b> Mouse wheel.
              </p>
              <h3>Tools</h3>
              <p>
                <b> Toggle between tools: </b> spacebar.
                <br />
                <b> Select Box label tool: </b> b.
                <br />
                <b> Select W/L tool: </b> w.
                <br />
                <b> Select W/L by region tool: </b> r.
                <br />
                <b> Select length tool: </b> l.
                <br />
                <b> Extend active box to current image: </b> ctrl-click.
                <br />
                <b> Sync stack posution: </b> s.
                <br />
              </p>
            </EuiText>
          </EuiFlyoutBody>
        </EuiFlyout>
      );
    }

    return (
      <EuiPage style={{ height: '100%' }}>
        <EuiHeader position="fixed" theme="dark">
          <EuiHeaderSectionItem border="none">
            <EuiHeaderLogo href="#">ML Viewer & Labeling tool</EuiHeaderLogo>
            <EuiHeaderBreadcrumbs breadcrumbs={!!user ? breadcrumbs : []} />
          </EuiHeaderSectionItem>
          {user && (
            <>
              <EuiHeaderSectionItem border="none">
                <EuiHeaderSectionItemButton aria-label="Flyout help" onClick={this.openFlyout}>
                  <EuiIcon type="help" color="subdued" size="l" />
                </EuiHeaderSectionItemButton>
                <EuiPopover
                  id="userContextMenu"
                  button={
                    <EuiHeaderSectionItemButton
                      aria-label="Account menu"
                      onClick={this.openAccountPopover}
                    >
                      <EuiAvatar
                        name={
                          user && user.firstname && user.lastname
                            ? user.firstname + ' ' + user.lastname
                            : ' '
                        }
                        size="m"
                      />
                    </EuiHeaderSectionItemButton>
                  }
                  isOpen={userMenuIsOpen}
                  closePopover={this.closeAccountPopover}
                  panelPaddingSize="none"
                  withTitle
                  anchorPosition="downLeft"
                >
                  <EuiContextMenu initialPanelId={0} panels={panels} />
                </EuiPopover>
              </EuiHeaderSectionItem>
            </>
          )}
        </EuiHeader>
        <EuiPageBody className="mainbody euiBody--headerIsFixed">
          <Router history={history}>
            <Switch>
              <Route exact={true} path="/login" component={SignIn} />
              <Route path="/">
                <React.Fragment>
                  <Router history={history}>
                    <React.Fragment>
                      <Route exact path="/">
                        <StudyList setBreadCrumbs={this.setBreadCrumbs} />
                      </Route>
                      <Route exact path="/view">
                        <Viewer setBreadCrumbs={this.setBreadCrumbs} />
                      </Route>
                    </React.Fragment>
                  </Router>
                </React.Fragment>
              </Route>
            </Switch>
          </Router>
        </EuiPageBody>
        {flyout}
      </EuiPage>
    );
  }
}

export default withUserStore(App);
