import React from 'react';
import withLabelStore from '../context/Labels';
import withUserStore from '../context/User';
import { observer } from 'mobx-react';
import BoxLabelPopover from './BoxLabelPopover';

import {
  EuiPanel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiHealth,
  EuiBadge,
} from '@elastic/eui';

class LabelListItem extends React.Component {
  state = {
    isPopoverOpen: false,
  };

  togglePopover = (e) => {
    this.state.isPopoverOpen ? this.closePopover() : this.openPopover();
  };

  openPopover = (e) => {
    this.setState({
      isPopoverOpen: true,
    });
  };

  closePopover = (e) => {
    this.setState({
      isPopoverOpen: false,
    });
  };

  render() {
    const { wrappedLabel, isSelected, color, labelStore, userStore } = this.props;
    const { useruuid } = userStore;
    const label = wrappedLabel.label;
    const isOwned = label.useruuid === useruuid;
    const { isPopoverOpen } = this.state;

    const avatarLabel = (() => {
      switch (label.type) {
        case 'range':
          return `R`;
        case 'forobb':
          return `V`;
        default:
          return null;
      }
    })();

    const firstLine = label.label.length === 0 ? 'none' : label.label.split(';').map(l=>l.length < 17 ? l : l.slice(0,17) + '…').join(', ');
    const secondLine = (() => {
      switch (label.type) {
        case 'range':
          return `${label.payload && label.payload.min + 1} - ${
            label.payload && label.payload.max + 1
          }`;
        case 'forobb':
          return `${label.payload.obb.halfWidth[0].toFixed(
            1
          )}x${label.payload.obb.halfWidth[1].toFixed(1)}x${label.payload.obb.halfWidth[2].toFixed(
            1
          )} mm`;
        default:
          return null;
      }
    })();

    return (
      <EuiPanel
        paddingSize="s"
        hasShadow={isSelected}
        onClick={() =>
          (labelStore.activeLabelUUID =
            labelStore.activeLabelUUID !== label.labeluuid ? label.labeluuid : undefined)
        }
      >
        <EuiFlexGroup responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar size="l" name={avatarLabel} color={color} />
          </EuiFlexItem>

          <EuiFlexItem>
            <BoxLabelPopover
              button={
                <EuiText
                  onMouseDown={this.togglePopover}
                  color={isSelected ? 'secondary' : 'subdued'}
                >
                  {firstLine}
                </EuiText>
              }
              isOpen={isPopoverOpen}
              closePopoverCallback={this.closePopover}
              wrappedLabel={wrappedLabel}
              anchorPosition="rightCenter"
            ></BoxLabelPopover>
            <EuiText color="subdued" size="s">
              {secondLine}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {isOwned && (!wrappedLabel.committed || wrappedLabel.pendind) && (
              <EuiHealth color={wrappedLabel.pendindSave ? 'warning' : 'danger'}></EuiHealth>
            )}
            {!isOwned && <EuiBadge>RO</EuiBadge>}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    );
  }
}

export default withUserStore(withLabelStore(observer(LabelListItem)));
