import React from 'react';
import withLabelStore from '../context/Labels';
import { observer } from 'mobx-react';
import './ImageSlider.scss';
import {
  euiPaletteColorBlind,
} from '@elastic/eui/lib/services';

const colors = euiPaletteColorBlind({rotations: 3, order: 'group', direction: 'both'})

class ImageSlider extends React.Component {
  onClick = (e) => {
    if (e.buttons !== 1) {
      return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const percent = (e.clientX - rect.left) / (rect.right - rect.left);
    if (!(percent >= 0 && percent <= 1)) {
      return;
    }
    const imageNumber = Math.floor(percent * (this.props.domain[1] + 1));

    this.props.onImageIndexUpdate(imageNumber);
  };

  onAdjustLabel = (e) => {
    e.preventDefault();
    const wrappedLabel = this.getActiveWrappedLabel();
    if (!wrappedLabel) {
      return;
    }
    const label = wrappedLabel && wrappedLabel.label;

    const imageNumber = this.props.current;

    if (imageNumber === label.payload.min || imageNumber === label.payload.max) {
      return;
    }

    if (Math.abs(imageNumber - label.payload.min) < Math.abs(imageNumber - label.payload.max)) {
      label.payload.min = imageNumber;
    } else {
      label.payload.max = imageNumber;
    }

    wrappedLabel.committed = false;
  };

  getActiveWrappedLabel = () => {
    const wrappedLabel = this.props.labelStore.activeLabelWrapped;

    if (
      !wrappedLabel ||
      !wrappedLabel.label ||
      wrappedLabel.label.type !== 'range' ||
      wrappedLabel.label.seriesuuid !== this.props.currentSeriesUUID
    ) {
      return;
    }
    return wrappedLabel;
  };

  render() {
    const {
      props: { current, domain, scores },
    } = this;

    const wrappedLabel = this.getActiveWrappedLabel();
    const label = wrappedLabel && wrappedLabel.label;

    let segments = [];
    for (let i = 0; i <= domain[1] + 1; i++) {
      segments.push((i / (domain[1] + 1)) * 100);
    }

    const separators = segments.slice(1,-1)

    const ip = [(current / (domain[1] + 1)) * 100, ((current + 1) / (domain[1] + 1)) * 100];

    const lp = label && [
      (label.payload.min / (domain[1] + 1)) * 100,
      ((label.payload.max + 1) / (domain[1] + 1)) * 100,
    ];
    return (
      <div style={{ marginRight: '5%', marginLeft: '5%', height: 30, width: '90%' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="20px"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          overflow="visible"
          className="sliderSvg"
          onMouseMove={this.onClick}
          onMouseDown={this.onClick}
          onDoubleClick={this.onAdjustLabel}
          onContextMenu={this.onAdjustLabel}
        >
          <line x1="0%" y1="10" x2="100%" y2="10" className="sliderBackground" />
          {label && (
            <line
              x1={`${lp[0]}%`}
              y1="10"
              x2={`${lp[1]}%`}
              y2="10"
              className="sliderLabelLine"
              style={{ stroke: wrappedLabel.color }}
            />
          )}
          {separators.length < 100 &&
            separators.map((s, i) => (
              <line key={i} x1={`${s}%`} y1="7" x2={`${s}%`} y2="13" className="sliderDivider" />
            ))}
          <line
            x1={`${(ip[0] + ip[1]) / 2 - 0.5}%`}
            y1="10"
            x2={`${(ip[0] + ip[1]) / 2 + 0.5}%`}
            y2="10"
            className="currentSliceIndicator"
          />
          {scores.map((s, i) => (
            s && s.prediction && ( s.probabilities[s.prediction] > 0.5 ||
                                   s.probabilities[s.prediction] >= Math.max(...Object.values(s.probabilities))) &&
              <line
              key={i}
              x1={`${segments[i]}%`}
              y1="18"
              x2={`${segments[i+1]}%`}
              y2="18"
              className="sliderPredictions"
              style={{ stroke: colors[Object.keys(s.probabilities).findIndex(bp => bp === s.prediction)] }}
            />
          ))}
          {scores.map((s, i) => (
            s && s.postProcessedPrediction && (s.postProcessedProbabilities[s.postProcessedPrediction] > 0.5 ||
                                               s.postProcessedProbabilities[s.postProcessedPrediction] >= Math.max(...Object.values(s.postProcessedProbabilities))) &&
              <line
              key={i}
              x1={`${segments[i]}%`}
              y1="22"
              x2={`${segments[i+1]}%`}
              y2="22"
              className="sliderPredictions"
              style={{ stroke: colors[Object.keys(s.postProcessedProbabilities).findIndex(bp => bp === s.postProcessedPrediction)] }}
            />
          ))}

        </svg>
      </div>
    );
  }
}

export default withLabelStore(observer(ImageSlider));
